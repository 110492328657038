import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Menu, MenuItem } from 'tt-ui-kit';

import styles from './NavbarLink.module.scss';

const NavbarLink = ({ href, Icon, itemIsMenu = false, menuItems, IconActive, onClick }) => {
  const location = useLocation();

  const [activeLink, setActiveLink] = useState(location.pathname);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <div className={styles.navbarItem}>
      {onClick ? (
        <span
          onClick={onClick}
          className={clsx(styles.navbarLink, activeLink === href ? styles.navbarLinkActive : '')}
        >
          <Icon
            className={clsx(styles.linkIcon, activeLink === href ? styles.linkIconActive : '')}
          />
        </span>
      ) : (
        <>
          {!itemIsMenu ? (
            <Link
              to={!href ? '/menu' : href}
              className={clsx(
                styles.navbarLink,
                activeLink === href ? styles.navbarLinkActive : ''
              )}
              underline="none"
            >
              <Icon
                className={clsx(styles.linkIcon, activeLink === href ? styles.linkIconActive : '')}
                style={{ width: 20, height: 20 }}
              />
            </Link>
          ) : (
            <Box className={styles.btnBox}>
              <button
                onClick={handleClick}
                type="button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className={styles.menuButton}
                style={{ border: 'none', backgroundColor: 'transparent' }}
              >
                {open ? (
                  <IconActive style={{ width: 20, height: 20 }} />
                ) : (
                  <Icon style={{ width: 20, height: 20 }} />
                )}
              </button>
              <Menu
                id="demo-positioned-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{
                  '.MuiPaper-root': {
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.13)',
                    borderRadius: '12px',
                    background: 'linear-gradient(135deg, #F2F2F2 0%, #FFFFFF 100%)',
                    top: 'auto !important',
                    bottom: '0',
                  },
                  '.MuiList-root': {
                    width: '48px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '14.4px 0 15.94px 0',
                    rowGap: '26px',
                  },
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <>
                  {menuItems}
                  <MenuItem className={styles.menuItem}>
                    {open ? <IconActive /> : <Icon />}
                  </MenuItem>
                </>
              </Menu>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default NavbarLink;
