/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-param-reassign */

import React, { useEffect } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3';

import { DirectTTAPIRequest, TTAPIRequest } from '../../utils/request';

import BlockChainTTAPIContext from './BlockChainTTAPIContext';

const { REACT_APP_NODE_ENV } = process.env;

const BlockChainTTAPIProvider = (props) => {
  const { children, web3, setWeb3 } = props;

  const { account, ethereum } = useMetaMask();

  //--------------------------

  const getBlockedIPList = async (bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/get_blocked_ip_list', {}, bHide);
    }
    return DirectTTAPIRequest('getBlockedIPList', {}, bHide);
  };

  const unblockIP = async (ip, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/unblock_ip', { ip: ip }, bHide);
    }
    return DirectTTAPIRequest('unblockIP', { ip: ip }, bHide);
  };

  const getRoleList = async (bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/get_role_list', {}, bHide);
    }
    return DirectTTAPIRequest('getRoleList', {}, bHide);
  };

  //--------------------------
  // Settings

  //  Methods

  // getContractMethodsByNameForAdmin
  const getSettingsFieldsList = async (name, version, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/methods/get_by_name',
        { name: name, version: version },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest(
      'getContractMethodsForAdmin',
      { name: name, version: version },
      bHide
    );
  };

  /**
   * @typedef ContractMethodsForAdminType
   * @property {String}  method_hash
   * @property {String}  method
   * @property {String}  type
   * @property {Number}  tt_type
   * @property {String}  tt_type_name
   * @property {String}  caption
   * @property {String}  hint
   * @property {Boolean} visible
   */
  /**
   * Получение списка методов контракта для админ панели
   * @param {String} address
   * @param {Number} netID
   * @param bHide
   * @returns {Promise<ContractMethodsForAdminType[]>}
   * @async
   */
  const getSettingsFieldsListByAddress = async (address, netID, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/methods/get_by_address',
        { address: address, netID: netID },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest(
      'getContractMethodsForAdmin',
      { address: address, netID: netID },
      bHide
    );
  };

  // setContractMethodForAdmin
  const setSettingsField = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/methods/set', data, bHide, true);
    }
    return DirectTTAPIRequest('setContractMethodForAdmin', data, bHide);
  };

  // delContractMethodForAdmin
  const deleteSettingsField = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/methods/del', data, bHide, true);
    }
    return DirectTTAPIRequest('delContractMethodForAdmin', data, bHide);
  };

  // Params

  /**
   * @typedef ContractMethodParamsForAdminType
   * @property {String}  method_hash
   * @property {String}  param
   * @property {String}  type
   * @property {String}  tt_type
   * @property {String}  tt_type_name
   * @property {Number}  ord
   * @property {String}  caption
   * @property {String}  hint
   * @property {Boolean} readonly
   * @property {Boolean} has_default_value
   * @property {?String} default_value
   * @property {?String} list_values
   * @property {?String} value_symbol
   * @property {?String} use_native_symbol
   * @property {?String} date_time_pattern
   * @property {?String} use_current_timestamp
   * @property {?String} add_caffee_to_payment
   * @property {?String} use_caffe_like_value
   * @property {?String} action
   * @property {?String} action_name
   * @property {Number}  pay_multiplier_value
   * @property {String}  path
   */
  /**
   * Получение списка методов контракта для админ панели
   * @param {String} methodHash
   * @param bHide
   * @returns {Promise<ContractMethodParamsForAdminType[]>}
   * @async
   */
  const getFieldsList = async (methodHash, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/methods/params/get',
        { method_hash: methodHash },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest(
      'getContractMethodParamsForAdmin',
      { method_hash: methodHash },
      bHide
    );
  };

  // setContractMethodParamForAdmin
  const setField = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/methods/params/set', data, bHide, true);
    }
    return DirectTTAPIRequest('setContractMethodParamForAdmin', data, bHide);
  };

  // delContractMethodParamForAdmin
  const deleteField = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/methods/params/del', data, bHide, true);
    }
    return DirectTTAPIRequest('delContractMethodParamForAdmin', data, bHide);
  };

  // Properties (tuples)

  /**
   * @typedef ContractMethodParamTuplePropertiesForAdminType
   * @property {String}  method_hash
   * @property {String}  param
   * @property {String}  type
   * @property {String}  tt_type
   * @property {String}  tt_type_name
   * @property {Number}  ord
   * @property {String}  caption
   * @property {String}  hint
   * @property {Boolean} readonly
   * @property {Boolean} has_default_value
   * @property {?String} default_value
   * @property {?String} list_values
   * @property {?String} value_symbol
   * @property {?String} use_native_symbol
   * @property {?String} date_time_pattern
   * @property {?String} use_current_timestamp
   */
  /**
   * Получение списка методов контракта для админ панели
   * @param {String} methodHash
   * @param {String} param
   * @param bHide
   * @returns {Promise<ContractMethodParamTuplePropertiesForAdminType[]>}
   * @async
   */
  const getFieldsPropertiesList = async (param, methodHash, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/methods/params/tuple/get',
        { param: param, method_hash: methodHash },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest(
      'getContractMethodParamTuplePropertiesForAdmin',
      { param: param, method_hash: methodHash },
      bHide
    );
  };

  // setContractMethodParamTuplePropertyForAdmin
  const setFieldsPropertiesList = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/methods/params/tuple/set',
        data,
        bHide,
        true
      );
    }
    return DirectTTAPIRequest('setContractMethodParamTuplePropertyForAdmin', data, bHide);
  };

  // delContractMethodParamTuplePropertyForAdmin
  const deleteFieldsPropertiesList = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/methods/params/tuple/del',
        data,
        bHide,
        true
      );
    }
    return DirectTTAPIRequest('delContractMethodParamTuplePropertyForAdmin', data, bHide);
  };

  // Lists

  // getContractParamList
  const getSettingsParamsList = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/lists/get', data, bHide, true);
    }
    return DirectTTAPIRequest('getContractParamLists', data, bHide);
  };

  // setContractParamList
  const setSettingsParamsList = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/lists/set', data, bHide, true);
    }
    return DirectTTAPIRequest('setContractParamList', data, bHide);
  };

  // delContractParamList
  const deleteSettingsParamsList = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/lists/del', data, bHide, true);
    }
    return DirectTTAPIRequest('delContractParamList', data, bHide);
  };

  /**
   * @typedef SettingsParamsListValuesType
   * @property {String|Number} id
   * @property {String}        name
   * @property {String}        description
   */
  /**
   * getContractParamListValues
   * @param {String}   listId
   * @param {Boolean} [bHide]
   * @returns {Promise<SettingsParamsListValuesType[]>}
   * @async
   */
  const getSettingsParamsListValues = async (listId, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/lists/values/get',
        { listID: listId },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest('getContractParamListValues', { listID: listId }, bHide);
  };

  // setContractParamListValues
  const setSettingsParamsListValues = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/lists/values/set', data, bHide, true);
    }
    return DirectTTAPIRequest('setContractParamListValues', data, bHide);
  };

  // delContractParamListValues
  const deleteSettingsParamsListValues = async (data, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest('/tt-block-chain/admin/contracts/lists/values/del', data, bHide, true);
    }
    return DirectTTAPIRequest('delContractParamListValues', data, bHide);
  };

  //--------------------------

  /**
   * @typedef ContractListType
   * @property {String} address
   * @property {String} balance
   * @property {String} deploy_time
   * @property {String} name
   * @property {Number} net_id
   * @property {String} net_name
   * @property {Number} version
   */
  /**
   * Получение списка контрактов для вкладки списка контрактов
   * @param {Number} [netID]
   * @param bHide
   * @returns {Promise<ContractListType[]>}
   */
  const getContractList = async (netID, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/get_contract_list',
        { netID: parseInt(netID, 10) },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest('getContractMethodsForAdmin', { netID: parseInt(netID, 10) }, bHide);
  };

  //--------------------------

  /**
   * @typedef ContractListForAdminType
   * @property {String}   name
   * @property {[Number]} versions
   * @property {[String]} addresses
   * @property {Boolean}  b_bytecode
   * @property {?String}  caption
   * @property {?String}  description
   */
  /**
   * Получение списка контрактов для админ панели
   * @param {Number} netID
   * @param bHide
   * @returns {Promise<ContractListForAdminType[]>}
   * @async
   */
  const getContractListForAdmin = async (netID, bHide) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      return TTAPIRequest(
        '/tt-block-chain/admin/contracts/get',
        { netID: parseInt(netID, 10) },
        bHide,
        true
      );
    }
    return DirectTTAPIRequest(
      'getContractListForAdmin',
      {
        netID: parseInt(netID, 10),
      },
      bHide
    );
  };

  const startBlockChainService = async (service, bHide) =>
    TTAPIRequest('/tt-block-chain/admin/start_app', { service: service }, bHide, true);

  const stopBlockChainService = async (service, bHide) =>
    TTAPIRequest('/tt-block-chain/admin/stop_app', { service: service }, bHide, true);

  //--------------------------

  useEffect(() => {
    console.info(`BlockChainAPIProvider: changed WEB3: ${!!web3}`);
    if (!web3) {
      setWeb3(new Web3(ethereum || window.ethereum));
    }
  }, [web3]);

  //--------------------------

  const defaultValue = {
    getBlockedIPList,
    unblockIP,
    getRoleList,
    getContractList,

    getSettingsFieldsList,
    getSettingsFieldsListByAddress,
    setSettingsField,
    deleteSettingsField,
    getFieldsList,
    setField,
    deleteField,
    getFieldsPropertiesList,
    setFieldsPropertiesList,
    deleteFieldsPropertiesList,
    getSettingsParamsList,
    setSettingsParamsList,
    deleteSettingsParamsList,
    getSettingsParamsListValues,
    setSettingsParamsListValues,
    deleteSettingsParamsListValues,

    TTAPIRequest,
    getContractListForAdmin,

    startBlockChainService,
    stopBlockChainService,
  };

  return (
    <BlockChainTTAPIContext.Provider value={defaultValue}>
      {children}
    </BlockChainTTAPIContext.Provider>
  );
};

export function useBlockChainTTAPI() {
  const context = React.useContext(BlockChainTTAPIContext);

  if (!context) {
    throw new Error('`useBlockChainTTAPI` should be used within a `BlockChainTTAPIProvider`');
  }

  return context;
}

export default BlockChainTTAPIProvider;
