import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AppAvatar, DotIcon, LoaderOn, Menu, MenuItem, RightIcon } from 'tt-ui-kit';
import { Link } from 'react-router-dom';
import styles from './NavBarNotificationButton.module.scss';

const NavBarNotificationButton = ({
  Icon,
  text,
  user,
  notifications,
  getUserNotifications,
  handleMarkAsReadNotifications,
  onClick,
  setShowNeedKYCModal,
  setIsAgreementModalOpen,
  logOut,
  redirectDomain,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    const unreadIds = notifications
      .filter((item) => !item.read_at)
      .map((item) => ({ id: item.id }));
    if (unreadIds.length > 0) {
      handleMarkAsReadNotifications(unreadIds);
    }
  };

  useEffect(() => {
    if (anchorEl) {
      getUserNotifications();
    }
  }, [anchorEl]);

  return (
    <div style={{ height: '100%' }}>
      <button
        onClick={onClick || handleClick}
        type="button"
        className={open ? styles.navbarLinkActive : styles.navbarLink}
      >
        <Icon className={clsx(styles.linkIcon, open ? styles.linkIconActive : '')} />
        <span className={styles.navbarLinktext}>{text}</span>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            paddingBottom: '20px',
            paddingTop: '9px',
            height: loading ? '355px' : 'auto',
          },
          '.MuiPaper-root': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.13)',
            borderRadius: '12px',
          },
        }}
      >
        {notifications.length > 0 && !loading ? (
          notifications.map((item) => (
            <MenuItem
              key={item.id}
              className={[styles.notification, !item.read_at ? styles.notificationUnread : '']}
            >
              <div>
                {item.user ? (
                  <AppAvatar
                    user={user}
                    nav={0}
                    userName={`${item.user.first_name} ${item.user.last_name}`}
                    src={item.user.avatar}
                    size="xs"
                    setShowNeedKYCModal={setShowNeedKYCModal}
                    setIsAgreementModalOpen={setIsAgreementModalOpen}
                    logOut={logOut}
                  />
                ) : null}
              </div>
              <div className={styles.notificationInfo}>
                <div className={styles.notificationTitle}>{item.message}</div>
                <div className={styles.notificationTitle}>{item.title}</div>
                <div
                  className={styles.notificationDescription}
                  dangerouslySetInnerHTML={{ __html: `${item?.content?.body}` }}
                />
              </div>
              {!item.read_at && (
                <DotIcon className={styles.unreadIcon} style={{ width: 30, height: 30 }} />
              )}
            </MenuItem>
          ))
        ) : !loading && !notifications.length ? (
          <MenuItem className={styles.notification} disabled>
            You have not notifications
          </MenuItem>
        ) : (
          <div className={styles.loader}>
            <LoaderOn />
          </div>
        )}
        {!loading && notifications.length > 0 && (
          <div className={styles.viewAllLinkBlock}>
            <Link
              to={
                redirectDomain
                  ? `${redirectDomain}/dashboard/notification`
                  : '/dashboard/notification'
              }
              onClick={() => setAnchorEl(false)}
              underline="none"
              className={styles.viewAllLink}
            >
              <span className={styles.viewAllLinkText}>View all</span>
              <RightIcon style={{ width: 20, height: 20 }} />
            </Link>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default NavBarNotificationButton;
