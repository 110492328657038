/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-param-reassign */

import React, { useEffect } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3';

import BlockChainAPIContext from './BlockChainAPIContext';
import { APIRequest } from '../../utils/request';

import { toBN } from '../../utils/web3_helper';

const BlockChainAPIProvider = (props) => {
  const { children, web3, setWeb3 } = props;

  const { account, ethereum } = useMetaMask();

  //--------------------------

  /**
   * @typedef TransactionHistory
   * @property {String} hash
   * @property {String} ts,
   * @property {String} from,
   * @property {?String} to,
   * @property {String} method,
   * @property {?BigInt} value,
   * @property {?BigInt} value_all,
   * @property {Number} blockNumber,
   * @property {Number} co2Tokens,
   * @property {String} co2Address,
   * @property {BigInt} co2Cost
   */
  /**
   * Получение списка последних транзакций в системе
   * @param {String} [wallet]
   * @param {boolean} bHide
   * @param {Number|String} [count=100] - Количество получаемых транзакций
   * @return {Promise<TransactionHistory[]>}
   */
  const getLatestTransaction = async (wallet, trDate, count, bHide = true) => {
    /** @type {TransactionHistory[]} */
    const data =
      (await APIRequest(
        'getLastNTransactions',
        {
          n: count === 'all' ? 'all' : parseInt(count, 10) || 100,
          account: wallet || '',
          date: trDate || undefined,
        },
        bHide
      )) || [];
    data.map((el) => {
      el.value = toBN(el.value);
      el.value_all = toBN(el.value_all);
      el.co2Cost = toBN(el.co2Cost);
      return el;
    });

    return data || [];
  };

  /**
   * @typedef TransactionEvent
   * @property {String}  hash
   * @property {String}  ts
   * @property {?String} to
   * @property {?Number} value
   * @property {?BigInt} cost
   */
  /**
   * Получение списка последних транзакций в системе
   * @param {Number|String} [cert]
   * @param {String} [wallet]
   * @param {Number|String} [count=100] - Количество получаемых транзакций
   * @param {boolean} bHide
   * @return {Promise<TransactionEvent[]>}
   */
  const getLatestEvents = async (cert, wallet, count, bHide = true) => {
    /** @type {[TransactionEvent]} */
    const data =
      (await APIRequest(
        'getLastNEvents',
        {
          n: parseInt(count, 10) || 100,
          cert: cert,
          wallet: wallet,
        },
        bHide
      )) || [];
    data.map((el) => {
      el.cost = toBN(el.cost);
      return el;
    });

    return data || [];
  };

  /**
   * @typedef TransactionDetail
   * @property {String}  hash
   * @property {String}  blockHash
   * @property {number}  blockNumber
   * @property {number}  nonce
   * @property {String}  ts
   * @property {?String} from
   * @property {String}  to
   * @property {?Number} value
   * @property {?String} method
   * @property {?BigInt} gas
   * @property {?BigInt} gasPrice
   * @property {?Number} tokens
   * @property {?Number} CaFFee
   * @property {?Number} transactionIndex
   * @property {?Number} type
   * @property {?String} input
   * @property {?String} r
   * @property {?String} s
   * @property {?String} v
   */
  /**
   * Поиск деталей транзакции по её хешу
   * @param {String} hash
   * @param {boolean} bHide
   * @return {Promise<{blockHash: String, blockNumber: Number, from: String, gas: Number, gasPrice:
   *   String, hash: String, input: String, nonce: Number, r: String, s: String, to: ?String,
   *   transactionIndex: Number, type: Number, v: String, value: String, ts: String, CaFFee:
   *   ?String, tokens: ?Number}>}\
   */
  const findTransactionByHash = async (hash, bHide = true) => {
    if (hash) {
      const req = await APIRequest('getTransactionByHash', { hash: hash }, bHide);

      if (req) {
        return req;
      }

      return {
        blockHash: '',
        blockNumber: NaN,
        from: '',
        gas: NaN,
        gasPrice: '',
        hash: '',
        input: '',
        nonce: NaN,
        r: '',
        s: '',
        to: null,
        transactionIndex: NaN,
        type: NaN,
        v: '',
        value: '',
        value_all: '',
        ts: '',
        CaFFee: null,
        tokens: null,
      };
    }
  };

  /**
   * @typedef HoldersList
   * @property {String}  hash
   * @property {String}  blockHash
   * @property {number}  blockNumber
   * @property {number}  nonce
   * @property {String}  ts
   * @property {?String} from
   * @property {String}  to
   * @property {?Number} value
   * @property {?String} method
   * @property {?BigInt} gas
   * @property {?BigInt} gasPrice
   * @property {?Number} tokens
   * @property {?Number} CaFFee
   * @property {?Number} transactionIndex
   * @property {?Number} type
   * @property {?String} input
   * @property {?String} r
   * @property {?String} s
   * @property {?String} v
   */
  /**
   * Получение списка холдеров
   * @return {Promise<[{wallet: String, value: String}]>}
   */
  const getHolders = async (wallet, bHide = true) => {
    const req = await APIRequest('getHolders', { wallet: wallet }, bHide);

    if (req) {
      return req;
    }

    return [];
  };

  /**
   * Получение общего количества монет в обращении
   * @return {Promise<BigInt>}
   */
  const getCurrentSupply = async (bHide = true) => {
    const req = await APIRequest('getCurrentSupply', undefined, bHide);

    if (req) {
      return toBN(req);
    }

    return toBN(NaN);
  };

  /**
   * Получение списка цифровых сертификатов
   * @return {Promise<[{wallet: String, value: String}]>}
   */
  const getCaFFees = async (acc, bHide = true) => {
    // eslint-disable-next-line no-param-reassign
    acc = acc || account;
    const req = await APIRequest('getCaFFees', { account: acc }, bHide);

    if (req) {
      return req;
    }

    return [];
  };

  //--------------------------

  useEffect(() => {
    console.info(`BlockChainAPIProvider: changed WEB3: ${!!web3}`);
    if (!web3) {
      setWeb3(new Web3(ethereum || window.ethereum));
    }
  }, [web3]);

  //--------------------------

  const defaultValue = {
    getLatestTransaction,
    getLatestEvents,
    findTransactionByHash,
    getHolders,
    getCurrentSupply,
    getCaFFees,
    APIRequest,
  };

  return (
    <BlockChainAPIContext.Provider value={defaultValue}>{children}</BlockChainAPIContext.Provider>
  );
};

export function useBlockChainAPI() {
  const context = React.useContext(BlockChainAPIContext);

  if (!context) {
    throw new Error('`useBlockChainAPI` should be used within a `BlockChainAPIProvider`');
  }

  return context;
}

export default BlockChainAPIProvider;
