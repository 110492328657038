import React from 'react';
import { MobileNavbarWrapper } from './components/Navbar';

export const MobileNavbar = ({
  user,
  setAnchorLoginEl,
  createAnInvitation,
  openCalculator,
  openCalendar,
  openBookmark,
  redirectDomain,
  marketTTUrl,
  digitalAssetsTTUrl,
  qaTTUrl,
}) => (
  <MobileNavbarWrapper
    user={user}
    setAnchorLoginEl={setAnchorLoginEl}
    redirectDomain={redirectDomain} // Home redirect full url
    createAnInvitation={createAnInvitation}
    openCalculator={openCalculator}
    openCalendar={openCalendar}
    openBookmark={openBookmark}
    marketTTUrl={marketTTUrl}
    digitalAssetsTTUrl={digitalAssetsTTUrl}
    qaTTUrl={qaTTUrl}
  />
);
