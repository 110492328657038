import { Image, AddIcon } from 'tt-ui-kit'; // TODO: remove
import styles from './ListMember.module.scss';

const ListMember = ({ memberData }) => {
  const addMember = () => {
    console.log('add member', memberData);
  };

  return (
    <>
      {memberData && (
        <div className={styles.listMemberWrapper}>
          <div className={styles.listMemberSecondWrapper}>
            <div
              className={styles.avatarWrapper}
              style={{ background: `center / cover no-repeat url(${memberData.avatar})` }}
            >
              <div className={styles.statusWrapper}>
                <div
                  className={
                    memberData.status === 'online'
                      ? styles.online
                      : memberData.status === 'offline'
                        ? styles.offline
                        : styles.busy
                  }
                />
              </div>
            </div>
            <div className={styles.name}>{memberData.name}</div>
          </div>
          <Image
            className={styles.addIcon}
            src={AddIcon}
            width={20}
            height={20}
            onClick={addMember}
          />
        </div>
      )}
    </>
  );
};

export default ListMember;
