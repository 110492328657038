import { gql } from '@apollo/client';

export const TOKEN = gql`
  fragment TokenFields on AuthPayload {
    access_token
    refresh_token
    expires_in
    token_type
  }
`;
export const user = gql`
  fragment userFields on User {
    id
    email
    first_name
    last_name
    created_at
    avatar
    invited_by_name
    ceo_of
    role_id
    role_name
  }
`;
