import React, { useState } from 'react';
import styles from './UnauthApi.module.scss';
import { Button } from 'tt-ui-lib/core';
import ArrowStepOne from '../../../../assets/images/ArrowStepOne.svg';
import ArrowStepTwo from '../../../../assets/images/ArrowStepTwo.svg';
import DecarbonizatorIcon from '../../../../assets/images/DecarbonizatorIcon.svg';
import ApiMainBlock from 'components/ApiMainBlock';
import BookCallModal from 'components/Modals/BookCallModal/indes';

const UnauthApi = ({ openDocumentation, bookbutton }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <ApiMainBlock bookbutton={bookbutton} setIsModalOpen={setIsModalOpen} />
      <div className={styles.stepsBlock}>
        <h2>
          Start transformation with{' '}
          <span style={{ color: 'rgba(45, 90, 168, 1)' }}>4 simple steps</span>
        </h2>
        <div className={styles.steps}>
          <div className={styles.step}>
            <div>
              <div className={styles.stepNumber}>1</div>
              <img className={styles.stepArrow} src={ArrowStepOne} alt="" />
            </div>
            <div className={styles.stepContent}>
              <h3>Book a call</h3>
              <p>Book a call to get an invite.</p>
            </div>
          </div>
          <div className={styles.step}>
            <div>
              <div className={styles.stepNumber}>2</div>
              <img className={styles.stepArrow} src={ArrowStepOne} alt="" />
            </div>
            <div className={styles.stepContent}>
              <h3>Sign Up</h3>
              <p>
                Once you receive an invitation, follow the instructions described in the invitation.
              </p>
            </div>
          </div>
          <div className={styles.step}>
            <div>
              <div className={styles.stepNumber}>3</div>
              <img
                style={{ bottom: '-10px' }}
                className={styles.stepArrow}
                src={ArrowStepTwo}
                alt=""
              />
            </div>
            <div className={styles.stepContent}>
              <h3>Create a Company Profile</h3>
              <p>
                After successfully registering, log in to your account and navigate to the Company
                Profile section. Here is the best place to present your business. Creating a
                comprehensive company profile by entering information and details about your
                business, including the name, industry, location, and a brief description, will help
                other users understand your company better and facilitate networking opportunities.
              </p>
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepNumber}>4</div>
            <div className={styles.stepContent}>
              <h3>Request API Keys</h3>
              <p>
                Once your company profile is set up, you may request keys to access specific
                features and functionalities within TransparenTerra. Look for the keys request
                section in your account dashboard, and from there, submit your request. Once your
                keys are approved, you will receive confirmation that you can fully utilize the
                platform&apos;s capabilities.
              </p>
            </div>
          </div>
        </div>
        <Button type="primary" className={styles.buttonBook} onClick={() => setIsModalOpen(true)}>
          Book a call
        </Button>
      </div>
      <div className={styles.listBlock}>
        <h2>
          List of TransparenTerra APIs to boost your business&apos; sustainability and transparency
        </h2>
        <div className={styles.decarbonizatorBlock} onClick={openDocumentation}>
          <img src={DecarbonizatorIcon} alt="" />
          <h3>Decarbonizator</h3>
          <p>
            Decarbonizator simplifies the process of neutralizing your website&apos;s carbon
            emissions. With precise tokenization, you can easily offset the impact of every
            transaction.
          </p>
        </div>
      </div>
      <BookCallModal isModelOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};
export default UnauthApi;
