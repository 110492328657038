/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import UserContext from './userContext';
import Cookies from 'js-cookie';

const UserState = ({ children }) => {
  const initialState = {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    created_at: '',
    avatar: '',
    invited_by_name: '',
    ceo_of: '',
    role_id: '',
    role_name: '',
    access_token: '',
    refresh_token: '',
  };

  const companyInitialState = {
    isCompanySelected: false,
    selectedCompany: null,
  };

  const getCompanyInitialState = () => {
    const data = localStorage.getItem('currentCompanyData');
    return data ? JSON.parse(data) : companyInitialState;
  };

  const [userState, setUserState] = useState(initialState);
  const [companyState, setCompanyState] = useState(getCompanyInitialState);
  const [isAdmin, setIsAdmin] = useState(false);

  const changeUserData = (user = {}) => {
    setUserState(user);
    setIsAdmin(!!user?.roles?.find((el) => el?.name === 'admin'));
  };

  const setSelectedCompany = (company) => {
    const data = {
      isCompanySelected: true,
      selectedCompany: company,
    };

    setCompanyState(data);
    localStorage.setItem('currentCompanyData', JSON.stringify(data));
    Cookies.set('companyId', company.id);
  };

  const removeSelectedCompany = () => {
    setCompanyState((user) => ({
      ...user,
      isCompanySelected: false,
      selectedCompany: null,
    }));
    localStorage.removeItem('currentCompanyData');
    Cookies.remove('companyId');
  };

  return (
    <UserContext.Provider
      value={{
        user: userState,
        selectedCompany: companyState,
        changeUserData,
        setSelectedCompany,
        removeSelectedCompany,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
