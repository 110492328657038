import React, { useState } from 'react';
import YotiModal from './YotiModal';
import { gql, useMutation } from '@apollo/client';
import { Button, Modal } from 'tt-ui-kit';

import styles from './KycModal.module.scss';

const GET_IDV_INIT = gql`
  mutation GetIdvInit($input: idvInitInput) @api(name: user) {
    idvInit(input: $input) {
      url
    }
  }
`;

export const KycModalWrapper = ({ isOpen, setIsOpen, userId, userKyc }) => {
  const [yotiUrl, setYotiUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = window.location.href;
  const [idvInit] = useMutation(GET_IDV_INIT, {
    variables: {
      input: {
        user_id: userId,
        feedback_url: location,
      },
    },
  });

  const onSubmit = async () => {
    const res = await idvInit({
      variables: { input: { user_id: userId, feedback_url: location } },
    });

    if (res?.errors) {
      console.log(res.errors);
    } else if (!res?.errors && res?.data?.idvInit?.url) {
      setYotiUrl(res?.data?.idvInit?.url);
      setIsOpen(false);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} title="Verification">
        {userKyc === 'pending' ? (
          <div>
            <p>
              Your data is being verified. The functionality will be available after KYC
              verification is completed.
            </p>
          </div>
        ) : (
          <>
            <div className={styles.needModalBody}>
              <p>
                <p>
                  Please pass the KYC process. To pass through KYC successfully, make sure that:
                </p>
                <ul>
                  <li>You are ready for the face recognition</li>
                  <li>You have your ID documents next to you</li>
                  <li>Your browser has permission to use the camera</li>
                  <li>Your webcam is working.</li>
                </ul>
                <br />
                <p>
                  Only fully verified users can be full members of the TransparenTerra community.
                </p>
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button type="primary" className={styles.acceptButton} onClick={onSubmit}>
                Start verification
              </Button>
            </div>
          </>
        )}
      </Modal>

      <YotiModal
        isModelOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleClose={() => setIsModalOpen(false)}
        yotiUrl={yotiUrl}
      />
    </>
  );
};
