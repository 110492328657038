import React from 'react';
import styles from './ApiMainBlock.module.scss';

import { Button } from 'tt-ui-lib/core';

import MainImage from '../../assets/images/mainImage.png';

const ApiMainBlock = ({ bookbutton, setIsModalOpen }) => (
  <div className={styles.mainBlock}>
    <div className={styles.mainBlockInfo}>
      <h1>Empower Your Platform with Sustainability, AI, and Blockchain</h1>
      <p>
        Our APIs seamlessly integrate the TransparenTerra advanced sustainability, AI, and
        blockchain tools directly into your website, ERP and CRM systems, digital platform, or
        marketplace. Leverage our solutions to:
      </p>
      <ul>
        <li>Drive sustainable transformation of your products.</li>
        <li>Increase engagement with your customers and users.</li>
      </ul>
      <p>
        Our suite of tools is designed to quickly elevate your website or marketplace to a
        cutting-edge, sustainable platform.
      </p>
      {bookbutton && (
        <Button type="primary" className={styles.buttonBook} onClick={() => setIsModalOpen(true)}>
          Book a call
        </Button>
      )}
    </div>
    <div>
      <img src={MainImage} alt="" width={380} />
    </div>
  </div>
);

export default ApiMainBlock;
