import { axiosClient, axiosSSOConfigPrivate } from '../../api';
import {
  API_CHAT_APPEND_CATEGORY,
  API_CHAT_CHANGE_CALL,
  API_CHAT_CREATE_CATEGORY,
  API_CHAT_DELETE_FOLDER,
  API_CHAT_DIRECT_MESSAGES,
  API_CHAT_END_DIRECT_CALL,
  API_CHAT_GET_CHATS,
  API_CHAT_GET_FOLDERS,
  API_CHAT_MESSAGES,
  API_CHAT_START_CALL,
  API_CHAT_USERS_LIST,
} from '../urls';

export const getChats = async (params) =>
  axiosClient.get(API_CHAT_GET_CHATS, await axiosSSOConfigPrivate({}, { params: params }));

export const getUsersList = async (userIds) =>
  axiosClient.get(
    API_CHAT_USERS_LIST,
    await axiosSSOConfigPrivate(
      {},
      {
        params: {
          id_user: userIds,
        },
      }
    )
  );

export const sendDirectMessage = async (data) =>
  axiosClient.post(
    API_CHAT_DIRECT_MESSAGES,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: {
          auth_user_id: data.userId,
          message: data.message,
          receiver_id: data.receiverId,
        },
      }
    )
  );

export const getChatMessages = async (data) => {
  const params = {
    auth_user_id: data.userId,
    page: data.page,
  };

  if (data.chatId) {
    params.chat_id = data.chatId;
  } else {
    params.receiver_id = data.receiverId;
  }

  return axiosClient.get(
    API_CHAT_MESSAGES,
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );
};

export const startDirectCall = async (data) =>
  axiosClient.post(
    API_CHAT_START_CALL,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: {
          auth_user_id: data.userId,
          receiver_id: data.receiverId,
        },
      }
    )
  );

export const changeCallStatus = async (params) =>
  axiosClient.post(
    API_CHAT_CHANGE_CALL,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );

export const endDirectCall = async (params) =>
  axiosClient.post(
    API_CHAT_END_DIRECT_CALL,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );

export const getChatFolders = async (params) =>
  axiosClient.get(
    API_CHAT_GET_FOLDERS,
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );

export const createChatCategory = async (params) =>
  axiosClient.post(
    API_CHAT_CREATE_CATEGORY,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );

export const appendChatCategoryChat = async (params) =>
  axiosClient.post(
    API_CHAT_APPEND_CATEGORY,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );

export const deleteChatFolder = async (params) =>
  axiosClient.post(
    API_CHAT_DELETE_FOLDER,
    {},
    await axiosSSOConfigPrivate(
      {},
      {
        params: params,
      }
    )
  );

// export const updateChatCategory = (params) =>
//   axiosClient.post(
//     '/chats/updateChatCategory',
//     {},
//     {
//       params: params,
//     },
//   );
//
// export const destroyChatCategory = (params) =>
//   axiosClient.post(
//     '/chats/destroyChatCategory',
//     {},
//     {
//       params: params,
//     },
//   );
