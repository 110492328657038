import React from 'react';
import clsx from 'clsx';
import { MUIModal } from 'tt-ui-kit';
import ModalContainer from '../ModalContainer';
import CheckboxWithButton from './CheckboxWithButton';

import styles from './ConsentModal.module.scss';

const ConsentModal = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  setIsAgree,
  isAgree,
  checkboxText,
  isShouldScroll,
  isScrolled,
  children,
  background,
  isFullScreenActive,
  setIsFullScreenActive,
  isFullScreen,
}) => (
  <MUIModal
    open={isModelOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    onClose={() => handleClose(setIsOpen)}
    BackdropProps={{
      classes: {
        root: isFullScreenActive ? styles.backDrop : '',
      },
    }}
  >
    <ModalContainer
      background={background || 'rgba(255, 255, 255, 0.8)'}
      isShouldScroll={isShouldScroll}
      handleClose={() => handleClose(setIsOpen)}
      isConsentModal
      isFullScreen={isFullScreen}
      isFullScreenActive={isFullScreenActive}
      setIsFullScreenActive={setIsFullScreenActive}
    >
      <div
        className={clsx(
          styles.consentWrapper,
          isFullScreenActive ? styles.fullScreenContentWrapper : ''
        )}
      >
        {children}
        <CheckboxWithButton
          isAgree={isAgree}
          setIsAgree={setIsAgree}
          text={checkboxText}
          handleClose={handleClose}
          setIsOpen={setIsOpen}
          isShouldScroll={isShouldScroll}
          isScrolled={isScrolled}
        />
      </div>
    </ModalContainer>
  </MUIModal>
);

export default ConsentModal;
