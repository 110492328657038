import React, { useEffect, useState, useRef } from 'react';

const MapComponent = ({
  coordinates,
  getCoordinates,
  getAddress,
  address,
  getAddressData,
  coordinatesList,
  isList,
  showSearch,
  zoom = 10,
  isEditable,
  activeRole,
}) => {
  const [markerPosition, setMarkerPosition] = useState(coordinates);
  const [locationAddress, setLocationAddress] = useState(address);

  const mapContainerRef = useRef(null);
  const searchInputRef = React.createRef();

  useEffect(() => {
    setMarkerPosition(coordinates);
  }, [coordinates]);

  const getCountry = (addrComponents) => {
    for (let i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] === 'country') {
        return addrComponents[i];
      }
      if (addrComponents[i].types.length === 2) {
        if (addrComponents[i].types[0] === 'political') {
          return addrComponents[i];
        }
      }
    }
    return {
      short_name: '',
      long_name: '',
    };
  };

  const getLocality = (addrComponents) => {
    for (let i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] === 'locality') {
        return addrComponents[i];
      }
      if (addrComponents[i].types.length === 2) {
        if (addrComponents[i].types[0] === 'locality') {
          return addrComponents[i];
        }
      }
    }
    return {
      short_name: '',
      long_name: '',
    };
  };

  const getPostalCode = (addrComponents) => {
    for (let i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] === 'postal_code') {
        return addrComponents[i];
      }
      if (addrComponents[i].types.length === 2) {
        if (addrComponents[i].types[0] === 'postal_code') {
          return addrComponents[i];
        }
      }
    }
    return {
      short_name: '',
      long_name: '',
    };
  };

  const changeMarkerPosition = (position) => {
    if (position) {
      getCoordinates(position);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const addressComponents = results[0].address_components;
          const addressData = {
            country_code: getCountry(addressComponents).short_name,
            address: results[0].formatted_address,
            country: getCountry(addressComponents).long_name,
            locality: getLocality(addressComponents).long_name,
            postcode: getPostalCode(addressComponents).short_name,
          };
          getAddressData(addressData);
          setLocationAddress(results[0].formatted_address);
        } else {
          getAddressData({});
          setLocationAddress('');
        }
      });
    }
  };

  useEffect(() => {
    if (isList) {
      if (coordinatesList && coordinatesList.length) {
        const map = new window.google.maps.Map(mapContainerRef.current, {
          center: coordinatesList[0].position,
          zoom: zoom,
          fullscreenControl: false,
          streetViewControl: false,
        });

        for (let i = 0; i < coordinatesList.length; i++) {
          // eslint-disable-next-line no-new
          const marker = new window.google.maps.Marker({
            position: coordinatesList[i].position,
            map,
            title: coordinatesList[i].title,
          });
          if (coordinatesList[i].params) {
            const infowindow = new window.google.maps.InfoWindow({
              content: coordinatesList[i].content,
              ariaLabel: 'Uluru',
            });
            if (activeRole === 'companies') {
              marker.addListener('click', () => {
                window.open(
                  `https://dev-market.transparenterra.com/dashboard/company/${coordinatesList[i].params}`
                );
              });
            } else {
              marker.addListener('click', () => {
                window.open(`/dashboard/profile/${coordinatesList[i].params}`);
              });
            }
            marker.addListener('mouseover', () => {
              infowindow.open({
                anchor: marker,
                map,
              });
            });
            marker.addListener('mouseout', () => {
              infowindow.close();
            });
          }
        }
      }
    } else {
      const map = new window.google.maps.Map(mapContainerRef.current, {
        center: markerPosition,
        zoom: zoom,
        fullscreenControl: false,
        streetViewControl: false,
      });

      const marker = new window.google.maps.Marker({
        position: markerPosition,
        map,
      });

      if (showSearch) {
        const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
        if (isEditable) {
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) return;

            const newPosition = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            marker.setPosition(newPosition);
            setMarkerPosition(newPosition);
            changeMarkerPosition(newPosition);
            map.setCenter(newPosition);
            setLocationAddress(place.formatted_address || '');
          });
        }
      }
      if (isEditable) {
        map.addListener('click', (event) => {
          const newMarkerPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          setMarkerPosition(newMarkerPosition);
          changeMarkerPosition(newMarkerPosition);
          marker.setPosition(newMarkerPosition);
        });
      }
    }
  }, [coordinatesList]);

  useEffect(() => {
    getAddress(locationAddress);
  }, [locationAddress]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {showSearch && (
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search"
          style={{
            width: 270,
            padding: 12,
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 1,
            border: 0,
          }}
        />
      )}
      <div
        ref={mapContainerRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

export default MapComponent;
