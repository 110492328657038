import React from 'react';
import { Modal } from 'tt-ui-kit';
import styles from './BlockedModal.module.scss';

const BlockedModal = ({ open, onClose, openSupport }) => (
  <Modal
    open={open}
    onClose={() => onClose()}
    onClick={(e) => e.stopPropagation()}
    title="Your account has been suspended"
  >
    <div className={styles.body}>
      <p>
        We regret to inform you that your account has been suspended. We apologize for any
        inconvenience this may cause. If you believe this suspension is in error, we kindly request
        you to contact our Support Сenter immediately for further assistance.
      </p>
      <div onClick={openSupport}>Support Сenter</div>
    </div>
  </Modal>
);

export default BlockedModal;
