import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';
import { ReactComponent as SeoMarkIcon } from '../../assets/svg/seoMark.svg';

import styles from './PersonalAreaBox.module.scss';

export const PersonalAreaBoxWrapper = ({
  user,
  pathname,
  firstNameProps,
  lastNameProps,
  avaProps,
  inviteProps,
  ceoProps,
  kycProps,
  links,
  ...restProps
}) => {
  const [isIndividual, setIsIndividual] = useState(true);

  useEffect(() => {
    if (user.roles && user.roles.length) {
      const roleExpert = user.roles?.find((elem) => elem.name === 'expert');
      const roleBusiness = user.roles?.find((elem) => elem.name === 'business');
      const roleInvestor = user.roles?.find((elem) => elem.name === 'investor');
      if (roleExpert || roleBusiness || roleInvestor) {
        setIsIndividual(false);
      } else {
        const roleHolder = user.roles?.find((elem) => elem.name === 'holder');
        if (roleHolder) {
          setIsIndividual(false);
        } else {
          const roleIndividual = user.roles?.find((elem) => elem.name === 'individual');

          if (roleIndividual) {
            setIsIndividual(true);
          } else {
            setIsIndividual(false);
          }
        }
      }
    }
  }, [user]);

  return (
    <div className={styles.menuContainer}>
      <div className={`${styles.container} ${restProps?.className}`} style={{ ...restProps.style }}>
        <form id="TheForm" method="post" target="TheWindow">
          <input type="hidden" name="login" value="something" />
          <input type="hidden" name="password" value="something" />
        </form>

        <div className={styles.content} style={{ display: user.id ? 'unset' : 'none' }}>
          <div className={styles.avatar}>{avaProps}</div>

          <div className={styles.row}>
            <p className={styles.name}>
              {firstNameProps.children
                ? `${firstNameProps.children} ${lastNameProps.children}`
                : ''}
            </p>
            {kycProps.children && kycProps.children === 'success' && (
              <span className={styles.successKyc}>KYC</span>
            )}
            {kycProps.children && kycProps.children === 'pending' && (
              <span className={styles.pendingKyc}>KYC</span>
            )}
          </div>

          <div className={styles.row}>
            {user.invited_by_name && (
              <>
                <p className={styles.invite}>Invited by {user.invited_by_name}</p>
              </>
            )}
          </div>

          <div className={styles.row} style={{ alignItems: 'center' }}>
            {user.ceo_of && (
              <>
                <SeoMarkIcon className={styles.ceoImg} />
                <p className={styles.ceo}>
                  CEO of <span style={{ color: '#4CAF50' }}>{user.ceo_of}</span>
                </p>
              </>
            )}
          </div>
        </div>

        {links && user?.id && (
          <div className={styles.linksRow}>
            {links.avatarMenu.map((link) => (
              <div key={`${link.name}`}>
                <Link
                  to={isIndividual ? link.individualLink : link.href}
                  color="inherit"
                  underline="none"
                  className={clsx(
                    pathname.includes(link?.href) || `${link?.href}`.includes(pathname)
                      ? styles.activeLink
                      : styles.links,
                    link?.disabled ? styles.disabledLink : ''
                  )}
                >
                  {link.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={`${styles.container} ${restProps?.className}`} style={{ ...restProps.style }}>
        <div className={styles.linksRow}>
          {links &&
            links.mainMenu.map((link) => (
              <div key={`${link.name}`}>
                <Link
                  to={link.href}
                  color="inherit"
                  underline="none"
                  className={clsx(
                    pathname.includes(link?.href) || `${link?.href}`.includes(pathname)
                      ? styles.activeLink
                      : styles.links,
                    link?.disabled ? styles.disabledLink : ''
                  )}
                >
                  {link.name}
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
