/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './AppAvatar.module.scss';
import { Avatar, Collapse, DownIcon, UpIcon, OrdersIcon } from 'tt-ui-kit';
import { Drawer } from 'tt-ui-lib/core';
import { getAvatarPathBySize } from '../../utils/common';
import { calculateLinks } from '../../../tt-left-menu';
import { ReactComponent as Present } from '../../assets/svg/present-icon.svg';
import { clsx } from 'clsx';

const AvatarDrawer = ({
  open,
  onClose,
  selectedCompany,
  isIndividual,
  selectPassKYC,
  user,
  logOut,
  marketTTUrl,
  mainTTUrl,
  userCompanies,
  selectCompany,
  selectToPersonalProfile,
  mode,
  onSelectMenu,
  onSelectHelp,
  onSelectSupport,
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseMenu, setOpenCollapseMenu] = useState(false);
  const [openCollapseHelp, setOpenCollapseHelp] = useState(false);

  const redirectMarket = (url) => `${marketTTUrl}${url}`;

  const otherProps = calculateLinks(mode);

  const helpMenu = [
    { name: 'Tutorial', href: 'tutorials' },
    { name: 'Presentations', href: 'presentations' },
    { name: 'Q&A', href: '' },
  ];

  const menu = [
    { name: 'About', href: 'about' },
    { name: 'How does it work?', href: 'work' },
    { name: 'Privacy Policy', href: 'privacy-policy' },
    { name: 'Honor code', href: 'honor' },
    { name: 'Terms of Services', href: 'terms' },
    { name: 'SDGs', href: 'sdgs' },
  ];

  // const nestedMenu = [
  //   { href: '/dashboard/feed', name: 'Products & Services', nested: true },
  //   { href: '/dashboard/feed', name: 'Hire experts', nested: true },
  //   { href: '/dashboard/feed', name: 'Carbon offset projects', nested: true },
  //   { href: '/dashboard/feed', name: 'Exchange', nested: true },
  // ];

  const ProfileProps = [
    {
      href: !selectedCompany?.isCompanySelected
        ? `${mainTTUrl || ''}/dashboard/profile/${user?.id}`
        : redirectMarket(`/dashboard/company/${selectedCompany.selectedCompany.id}/edit`),
      name: !selectedCompany?.isCompanySelected ? 'Profile' : 'Company profile',
    },
    { href: `${mainTTUrl || ''}/dashboard/content`, name: 'My Content' },
    { href: `${mainTTUrl || ''}/dashboard/saved-folders`, name: 'Saved Folders' },
    { href: `${mainTTUrl || ''}/dashboard/archived`, name: 'Archived' },
    {
      href: `${mainTTUrl || ''}/dashboard/change-role`,
      name: 'Change your role',
      forPersonal: true,
    },
    { href: `${mainTTUrl || ''}/dashboard/referral`, name: 'Referral program', forPersonal: true },
    // { href: '/dashboard/feed', name: 'Business account' },
    { href: `${mainTTUrl || ''}/dashboard/settings`, name: 'Settings & secure' },
    // { href: `${mainTTUrl || ''}/dashboard/help`, name: 'Help' },
  ];

  const handleClick = (e) => {
    onClose();
  };

  const showMenu = ProfileProps.map((link) => {
    if (link.forPersonal) {
      if (!selectedCompany?.isCompanySelected) {
        return (
          <div className={styles.linksRow} key={link.name} style={{ display: 'flex' }}>
            <Link
              color="inherit"
              underline="none"
              to={link.href}
              style={{ marginRight: 10 }}
              className={styles.links}
              onClick={handleClick}
            >
              {link.name}
            </Link>
            {link.name === 'Referral program' && <Present />}
          </div>
        );
      }
      return null;
    }

    return (
      <div className={styles.linksRow} key={link.name}>
        <Link
          color="inherit"
          underline="none"
          to={link.href}
          className={styles.links}
          onClick={handleClick}
        >
          {link.name}
        </Link>
      </div>
    );
  });

  return (
    <Drawer placement="right" closable={false} open={open} onClose={onClose} width={295}>
      <div className={styles.modalDialogBlock} onClick={(event) => event.stopPropagation()}>
        <div className={styles.user}>
          <div className={styles.userInfo}>
            <div className={styles.name}>
              {user.first_name ? `${user.first_name} ${user.last_name}` : ''}
            </div>
            <div className={styles.invite}>
              {user.invited_by_name ? `invited by ${user.invited_by_name}` : ''}
            </div>
            {user.ceo_of && (
              <div className={styles.ceoRow}>
                <OrdersIcon style={{ width: 13, height: 13 }} />
                <div className={styles.ceo}>{user.ceo_of ? user.ceo_of : ''}</div>
              </div>
            )}
          </div>
          <div className={styles.avatarRow}>
            <Avatar
              alt={selectedCompany.isCompanySelected ? '' : `${user.first_name} ${user.last_name}`}
              src={
                selectedCompany.isCompanySelected
                  ? getAvatarPathBySize(selectedCompany.selectedCompany.logos, 'mediumPath')
                  : user.avatar
                    ? user.avatar
                    : '/images/navbar/Avatar.png'
              }
              sx={{ width: 60, height: 60 }}
            />
          </div>
        </div>

        <div className={styles.linksRow} style={{ paddingBottom: 20 }}>
          <div className={styles.ProfileLinks}>
            {showMenu}
            <>
              {helpMenu && helpMenu.length > 0 && (
                <div className={`${styles.dropdownNestedRootDrawer} ${styles.dropdownItem}`}>
                  <span
                    className={styles.dropdownTitle}
                    onClick={() => setOpenCollapseHelp((val) => !val)}
                  >
                    <span>Help</span>
                    {openCollapseHelp ? (
                      <UpIcon style={{ width: 18, height: 18 }} />
                    ) : (
                      <DownIcon style={{ width: 18, height: 18 }} />
                    )}
                  </span>

                  <Collapse in={openCollapseHelp} timeout="auto" unmountOnExit>
                    <div className={styles.dropdownBody}>
                      {helpMenu.map((item) => (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          className={styles.dropdownItemCompany}
                          onClick={() => onSelectHelp(item)}
                        >
                          <span>{item.name}</span>
                        </div>
                      ))}
                      <div className={styles.dropdownItemCompany} onClick={onSelectSupport}>
                        <span>Support</span>
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}
            </>
            <>
              {menu && menu.length > 0 && (
                <div className={`${styles.dropdownNestedRootDrawer} ${styles.dropdownItem}`}>
                  <span
                    className={styles.dropdownTitle}
                    onClick={() => setOpenCollapseMenu((val) => !val)}
                  >
                    <span>Menu</span>
                    {openCollapseMenu ? (
                      <UpIcon style={{ width: 18, height: 18 }} />
                    ) : (
                      <DownIcon style={{ width: 18, height: 18 }} />
                    )}
                  </span>

                  <Collapse in={openCollapseMenu} timeout="auto" unmountOnExit>
                    <div className={styles.dropdownBody}>
                      {menu.map((item) => (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          className={styles.dropdownItemCompany}
                          onClick={() => onSelectMenu(item)}
                        >
                          <span>{item.name}</span>
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </div>
              )}
            </>
            {selectedCompany?.isCompanySelected && (
              <div className={`${styles.links}`} onClick={selectToPersonalProfile}>
                Personal account
              </div>
            )}
            <>
              {userCompanies && userCompanies.length > 0 && (
                <div className={`${styles.dropdownNestedRootDrawer} ${styles.dropdownItem}`}>
                  <span
                    className={styles.dropdownTitle}
                    onClick={() => setOpenCollapse((val) => !val)}
                  >
                    <span>Business account</span>
                    {openCollapse ? (
                      <UpIcon style={{ width: 18, height: 18 }} />
                    ) : (
                      <DownIcon style={{ width: 18, height: 18 }} />
                    )}
                  </span>

                  <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <div className={styles.dropdownBody}>
                      {userCompanies.map((item) => (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          className={styles.dropdownItemCompany}
                          onClick={() => selectCompany(item)}
                        >
                          {item.logos.length > 0 ? (
                            <Avatar
                              alt={item.company_name}
                              src={getAvatarPathBySize(item.logos, 'smallPath')}
                              sx={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <Avatar alt={item.company_name} src="" sx={{ width: 24, height: 24 }} />
                          )}
                          <span>{item.company_name}</span>
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </div>
              )}
            </>

            {!isIndividual && user?.kyc !== 'success' && user?.kyc !== 'pending' && (
              <div className={styles.links} onClick={selectPassKYC}>
                Pass KYC
              </div>
            )}

            <div className={styles.links} style={{ color: '#D32F2F' }} onClick={() => logOut()}>
              Log out
            </div>
          </div>

          <div className={styles.ProfileLinks} style={{ paddingTop: 10 }}>
            {otherProps?.avatarMenu?.map((link) => (
              <div key={link.name}>
                {link?.isNotIndividual && isIndividual ? null : link?.parent ? (
                  <div className={styles.parent}>
                    <Link
                      color="inherit"
                      underline="none"
                      onClick={handleClick}
                      to={link.href}
                      className={clsx(styles.links, link?.disabled ? styles.disabledLink : '')}
                    >
                      {link.name}
                    </Link>
                  </div>
                ) : (
                  <div className={styles.linksRow}>
                    <Link
                      color="inherit"
                      underline="none"
                      to={link.href}
                      className={clsx(styles.links, link?.disabled ? styles.disabledLink : '')}
                      onClick={handleClick}
                    >
                      {link.name}
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>

          {otherProps?.mainMenu?.map((link) => (
            <div key={link.name}>
              {link?.isNotIndividual && isIndividual ? null : link?.parent ? (
                <div className={styles.parent}>
                  <Link
                    color="inherit"
                    underline="none"
                    onClick={handleClick}
                    to={link.href}
                    className={clsx(styles.links, link?.disabled ? styles.disabledLink : '')}
                  >
                    {link.name}
                  </Link>
                </div>
              ) : (
                <div className={styles.linksRow}>
                  <Link
                    color="inherit"
                    underline="none"
                    to={link.href}
                    className={clsx(styles.links, link?.disabled ? styles.disabledLink : '')}
                    onClick={handleClick}
                  >
                    {link.name}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default AvatarDrawer;
