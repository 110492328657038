import AppState from './App/AppState';
import AppContext from './App/appContext';
import messengerContext from './Messenger/messengerContext';
import userContext from './User/userContext';
import notificationContext from './Notification/notificationContext';

export const allContexts = {
  app: AppContext,
  user: userContext,
  messenger: messengerContext,
  notification: notificationContext,
};

export { AppState, AppContext };
