import React, { useState } from 'react';
import { FormControlLabel, MUICheckBox } from 'tt-ui-kit';

import styles from './CheckboxWithButton.module.scss';

const CheckboxWithButton = ({
  setIsAgree,
  text,
  handleClose,
  setIsOpen,
  isShouldScroll,
  isScrolled,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={styles.wrapper}>
      <FormControlLabel
        control={
          <MUICheckBox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            disabled={isShouldScroll && !isScrolled}
          />
        }
        label={text}
      />
      <button
        type="button"
        className={styles.btn}
        disabled={!isChecked}
        onClick={() => {
          setIsAgree(true);
          handleClose(setIsOpen);
        }}
      >
        Proceed
      </button>
    </div>
  );
};

export default CheckboxWithButton;
