import React, { useEffect, useState, useRef } from 'react';
import styles from './QuestionItem.module.scss';

import { UpIcon, DownIcon } from 'tt-ui-kit';

const getElementDimensions = (element) => {
  const olElement = element.querySelector('ol');
  const height = element.offsetHeight;
  const width = element.offsetWidth;
  const style = getComputedStyle(element);

  console.log(olElement);

  return {
    height: olElement
      ? height + parseInt(style.marginTop, 36) + parseInt(style.marginBottom, 36)
      : height + parseInt(style.marginTop, 15) + parseInt(style.marginBottom, 15),
    width: width + parseInt(style.marginLeft, 20) + parseInt(style.marginRight, 20),
  };
};

const QuestionItem = ({ item }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [contentHeight, setContentHeight] = useState(0);
  const contentTextRef = useRef(null);

  useEffect(() => {
    if (contentTextRef.current) {
      setContentHeight(getElementDimensions(contentTextRef.current).height);
    }
  }, []);

  const handleClick = (event) => {
    setExpandedIndex(!expandedIndex);
  };

  return (
    <div className={styles.questionsItem} onClick={handleClick}>
      <div className={styles.questionsTitle}>
        <p>{item.question}</p>
        {expandedIndex ? <UpIcon style={{ width: 24 }} /> : <DownIcon style={{ width: 24 }} />}
      </div>
      <div
        className={`${styles.content} ${expandedIndex ? styles.expanded : ''}`}
        style={{ maxHeight: expandedIndex ? contentHeight : undefined }}
      >
        <div ref={contentTextRef} className={styles.answerBlock}>
          <p className={styles.text}>{item.answer}</p>
          {item.enumeration && item.enumeration.length > 0 && (
            <ol>
              {item.enumeration?.map((itemEnumeration) => (
                <li key={itemEnumeration}>{itemEnumeration}</li>
              ))}
            </ol>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
