import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/Layout/PageContainer';
import { createTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { AdminNavbar } from '../../modules/tt-navmenu';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import DecarbonizatorPage from './Decarbonizator';
import styles from './AdminRoutes.module.scss';
import { useLazyQuery } from '@apollo/client';
import { GET_CURRENT_USER } from '../../api';
import { openNotification } from 'tt-ui-lib/core';
import { useSelectedContext } from '../../context/contextSelector';

const ADMIN_ROUTES_INFO = [
  {
    path: '/',
    element: <Navigate to="/admin/decarbonizator" />,
  },
  {
    path: '/decarbonizator',
    element: <DecarbonizatorPage />,
    name: 'Decarbonizator',
  },
];

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const AdminRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelectedContext((context) => context.user);
  const [breadcrumb, setBreadcrumb] = useState({
    path: '',
    name: '',
  });
  const [showNavbar, setShowNavbar] = useState(false);

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER);

  const getUserValue = async () => {
    const userValue = await getCurrentUser();
    const isAdmin = userValue?.data?.currentUser?.roles?.findIndex((role) => role.name === 'admin');
    if (isAdmin !== undefined && isAdmin !== -1) {
      setShowNavbar(true);
    } else {
      navigate('/dashboard/feed', {
        replace: true,
        state: {
          isOpenNotification: true,
          openNotification: openNotification({
            type: 'error',
            message: 'Access denied',
          }),
        },
      });
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getUserValue();
    })();
  }, []);

  useEffect(() => {
    if (location && location.pathname) {
      const pathname = location.pathname.slice(6);
      const route = ADMIN_ROUTES_INFO.find((item) => item.path === pathname);
      if (route) {
        setBreadcrumb({
          path: route.path,
          name: route.name,
        });
      }
    }
  }, [location]);

  return (
    <PageContainer>
      <Box className={styles.pageContent}>
        <AdminNavbar
          showNavbar={showNavbar}
          theme={theme}
          baseUrl={process.env.REACT_APP_MAIN || ''}
          baseFceUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
          baseApiUrl=""
          mode="api"
        />

        <div>
          <Breadcrumb
            data={[
              { href: '/', name: 'TransparenTerra' },
              { href: '/', name: 'Home' },
              { href: '/dashboard/feed', name: 'Admin' },
              { href: `/admin${breadcrumb.path}`, name: breadcrumb.name },
            ]}
          />
        </div>
        <Box className={styles.pageContentBox}>
          <Routes>
            {ADMIN_ROUTES_INFO.map((routeInfo) => (
              <Route path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
            ))}
          </Routes>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default AdminRoutes;
