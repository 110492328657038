import { gql } from '@apollo/client';

export const GET_AGREEMENT = gql`
  query @api(name: user) {
    agreementPrivacyAndHonor {
      id
      field
      body
    }
  }
`;

export const GET_URL_FOR_PUBLIC_UPLOAD = gql`
  mutation getPublicUrl($input: sFile) @api(name: user) {
    getUrlForPublicUpload(input: $input) {
      url
      download_url
    }
  }
`;

export const FORGOT_USER_PASSWORD = gql`
  mutation ($input: ForgotPasswordInput!) @api(name: user) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;
