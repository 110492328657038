/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react';

import { toBN, toJSON } from '../../utils/web3_helper';

import {
  chainSettings,
  hideStandby,
  showError,
  showStandby,
  showSuccess,
  updateWalletStateTimeout,
} from '../../utils/common';

import { APIRequest, callContractMethod, callContractMethodWithAddress } from '../../utils/request';

import { useMetaMask } from 'metamask-react';
import Web3 from 'web3';

import CaFFeeContext from './CaFFeeContext';

import { Modal } from 'tt-ui-kit';

const CaFFeeProvider = (props) => {
  const { children, web3, setWeb3 } = props;

  const { chainId, ethereum, account, status } = useMetaMask();

  /** Адрес текущего карбонового сертификата @type {String} */
  const [co2CertAddr, setCO2CertAddr] = useState('');
  /** Символ текущего карбонового сертификата @type {String} */
  const [co2TokenSymbol, setCO2TokenSymbol] = useState('');
  /** Стоимость одного токена текущего карбонового сертификата @type {BigInt} */
  const [co2TokenCost, setCO2TokenCost] = useState(toBN(0));
  /** Номер материнского сертификата для текущего карбонового сертификата @type {String} */
  const [co2CertNumber, setCO2CertNumber] = useState('');
  /** Бренд материнского сертификата для текущего карбонового сертификата @type {String} */
  const [co2CertBrand, setCO2CertBrand] = useState('South Pole');
  /** Дата выпуска материнского сертификата для текущего карбонового сертификата @type {String} */
  const [co2CertDate, setCO2CertDate] = useState('26-12-2022');
  /** Количество CBD токенов на текущем карбоновом сертификате @type {Number} */
  const [co2CertTokenCount, setCO2CertTokenCount] = useState(0);

  /** Флаг отображения информации о том, что такое CaFFee @type {Boolean} */
  const [showAboutCaFFee, setShowAboutCaFFee] = useState(false);

  //---------------------------------------

  const _memo = {};

  /**
   * Сохранение кеша значения в структуру
   * @param prop
   * @param index
   * @param value
   * @private
   */
  const _setValToMemo = (prop, index, value) => {
    if (!_memo[prop]) {
      _memo[prop] = {};
    }
    if (!_memo[prop][index]) {
      _memo[prop][index] = {};
    }
    _memo[prop][index].timeStamp = new Date().getTime();
    _memo[prop][index].value = value;
  };

  const _getValFromMemoByContract = async (
    contract,
    method,
    params,
    setter,
    hide,
    addr,
    formatter,
    force
  ) => {
    if (chainSettings?.chainId === chainId) {
      const _tt = new Date().getTime();
      const _name = `${contract}.${method}`;
      let _params = toJSON(params);

      if (addr) {
        _params += `|${addr}`;
      }

      let value;

      // eslint-disable-next-line no-shadow
      const getter = async (contract, method, params, hide, addr) =>
        addr
          ? callContractMethodWithAddress(
              web3,
              status,
              account,
              addr,
              contract,
              method,
              params,
              hide
            ).catch((err) => showError(err))
          : callContractMethod(web3, status, account, contract, method, params, hide).catch((err) =>
              showError(err)
            );

      const tmp = _memo && _memo[_name] && _memo[_name][_params];
      if (force || !tmp || _tt - tmp.timeStamp > updateWalletStateTimeout) {
        value = await getter(contract, method, params, hide, addr);
        if (formatter) {
          value = formatter(value);
        }
        _setValToMemo(_name, _params, value);
        // eslint-disable-next-line no-unused-expressions
        setter && setter(value);
      } else {
        value = tmp.value;
      }

      return value;
    }
  };

  const _getValFromMemoByAPI = async (method, params, setter, hide, formatter, force) => {
    const _tt = new Date().getTime();
    const _name = `API.${method}`;
    const _params = toJSON(params);

    let value;

    const tmp = _memo && _memo[_name] && _memo[_name][_params];
    if (force || !tmp || _tt - tmp.timeStamp > updateWalletStateTimeout) {
      value = await APIRequest(method, params, hide);
      if (formatter) {
        value = formatter(value);
      }
      _setValToMemo(_name, _params, value);
      // eslint-disable-next-line no-unused-expressions
      setter && setter(value);
    } else {
      value = tmp.value;
    }

    return value;
  };

  /**
   * Геттер Адреса карбонового сертификата
   * @param {Number} [index] - Порядковый номер карбонового сертификата (пусто - текущий)
   * @return {Promise<String>}
   */
  const getCO2CertAddress = async (index) =>
    index >= 0
      ? _getValFromMemoByContract(
          'CarbonFootprintFactory',
          'certificateAddress',
          [index],
          setCO2CertAddr,
          true
        )
      : _getValFromMemoByContract(
          'CarbonFootprintFactory',
          'CurrentCertificate',
          [],
          setCO2CertAddr,
          true
        );

  /**
   * Геттер Символа карбонового сертификата
   * @param {Number} [index] - Порядковый номер карбонового сертификата (пусто - текущий)
   * @return {Promise<String>}
   */
  const getCO2TokenSymbol = async (index) => {
    const addr = await getCO2CertAddress(index);
    if (addr) {
      return _getValFromMemoByContract(
        'CarbonFootprint',
        'symbol',
        [],
        setCO2TokenSymbol,
        true,
        addr
      );
    }
    showError('error while getting contract address (1)');
  };

  /**
   * Геттер Символа карбонового сертификата по адресу
   * @param {Number} [addr] - Адресс карбонового сертификата
   * @return {Promise<String>}
   */
  const getCO2TokenSymbolByAddress = async (addr) => {
    if (addr) {
      return _getValFromMemoByContract(
        'CarbonFootprint',
        'symbol',
        [],
        setCO2TokenSymbol,
        true,
        addr
      );
    }
    showError('error while getting contract address (1)');
  };

  /**
   * Геттер стоимости одного карбонового токена
   * @param {Number} [index] - Порядковый номер карбонового сертификата (пусто - текущий)
   * @return {Promise<BigInt>}
   */
  const getCO2TokenCost = async (index) => {
    if (index >= 0) {
      const addr = await getCO2CertAddress(index);
      if (addr) {
        return toBN(
          await _getValFromMemoByContract(
            'CarbonFootprint',
            'getTokensCost',
            [1],
            setCO2TokenCost,
            true,
            addr
          )
        );
      }
      showError('error while getting contract address (2)');
    } else {
      return toBN(
        await _getValFromMemoByContract(
          'CarbonFootprintFactory',
          'getTokensCost',
          [1],
          setCO2TokenCost,
          true
        )
      );
    }
  };

  /**
   * Геттер Номера карбонового сертификата
   * @param {Number} [index] - Порядковый номер карбонового сертификата (пусто - текущий)
   * @return {Promise<String>}
   */
  const getCO2CertNumber = async (index) => {
    const addr = await getCO2CertAddress(index);
    if (addr) {
      return _getValFromMemoByContract(
        'CarbonFootprint',
        'CertificateID',
        [],
        setCO2CertNumber,
        true,
        addr
      );
    }
    showError('error while getting contract address (3)');
  };

  /**
   * Геттер Компании карбонового сертификата
   * @param {Number} [index] - Порядковый номер карбонового сертификата (пусто - текущий)
   * @return {Promise<String>}
   */
  const getCO2CertBrand = async (index) => {
    const addr = await getCO2CertAddress(index);
    if (addr) {
      return _getValFromMemoByContract('CarbonFootprint', 'Brand', [], setCO2CertBrand, true, addr);
    }
    showError('error while getting contract address (4)');
  };

  /**
   * Геттер даты карбонового сертификата
   * @param {Number} [index] - Порядковый номер карбонового сертификата (пусто - текущий)
   * @return {Promise<String>}
   */
  const getCO2CertDate = async (index) => {
    const addr = await getCO2CertAddress(index);
    if (addr) {
      return _getValFromMemoByContract(
        'CarbonFootprint',
        'CreationDate',
        [],
        setCO2CertDate,
        true,
        addr,
        (val) => new Date(parseInt(val, 10) * 1000).toISOString().substring(0, 10)
      );
    }
    showError('error while getting contract address (5)');
  };

  /**
   * Геттер количества карбоновых токенов на сертификате
   * @param {Number} [index] - порядковый номер карбонового сертификата - пусто - текущий
   * @return {Promise<Number>}
   */
  const getCO2CertTokenCount = async (index) => {
    const addr = await getCO2CertAddress(index);
    if (addr) {
      return parseInt(
        await _getValFromMemoByContract(
          'CarbonFootprint',
          'balanceOf',
          [account],
          setCO2CertTokenCount,
          true,
          addr
        ),
        10
      );
    }
    showError('error while getting contract address (6)');
  };

  /**
   * Геттер общая информация о сертификате
   * @param {String} [address] - Адрес сертификата (если не передан, то используется текущий)
   * @return {Promise<?{addr: String, fee: String, number: String, URL: String, note: String,
   *   brand: String, value: String, date: String, initTokens: String, freeTokens: String}>}
   */
  const getCO2CertInfo = async (address) => {
    if (address) {
      return _getValFromMemoByAPI('getCertificateInfo', { address: address }, null, false);
    }
    showError('error while getting contract address (7)');
  };

  /**
   * Функция добавления токенов пользователя из контракта восполнения карбонового следа в кошелёк
   * MetaMask
   * @param {Number?} [ind]    - Порядковый номер сертификата (если пусто, то текущий)
   * @param {String}  [symbol] -
   * @return {Promise<void>}
   */
  const addCO2TokenToMetaMask = async (address, ind, symbol) => {
    showStandby();

    try {
      const tokenAddress = address || (await getCO2CertAddress(ind));
      const _symbol = symbol || (await getCO2TokenSymbolByAddress(tokenAddress));

      const tokenDecimals = 18;
      const tokenImage = `${chainSettings.rpcUrls[0]}/img/token.svg`;

      if (tokenAddress && _symbol && tokenDecimals) {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: _symbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });
        showSuccess('Open your MetaMask wallet to continue the process of adding tokens.');
      }
    } catch (err) {
      showError(err?.message);
    } finally {
      hideStandby();
    }
  };

  const _showAboutCaFFee = () => {
    setShowAboutCaFFee(true);
  };

  //--------------------------

  useEffect(() => {
    console.info(`CaFFeeProvider: changed WEB3: ${!!web3}`);
    if (!web3) {
      setWeb3(new Web3(ethereum || window.ethereum));
    }
  }, [web3]);

  //--------------------------

  const defaultValue = {
    co2CertAddr,
    co2TokenSymbol,
    co2TokenCost,
    co2CertNumber,
    co2CertBrand,
    co2CertDate,
    co2CertTokenCount,

    getCO2CertAddress,
    getCO2TokenSymbol,
    getCO2TokenCost,
    getCO2CertNumber,
    getCO2CertBrand,
    getCO2CertDate,
    getCO2CertTokenCount,
    getCO2CertInfo,

    addCO2TokenToMetaMask,

    showAboutCaFFee: _showAboutCaFFee,
  };

  return (
    <CaFFeeContext.Provider value={defaultValue}>
      {children}

      <Modal // How to disconnect the wallet?
        open={showAboutCaFFee}
        onClose={() => setShowAboutCaFFee(false)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="About CaFFee"
      >
        <p className="p">
          Users automatically redeem the carbon footprint of each transaction in FCE Blockchain with
          CAFFEE. The price of the CAFFEE is dynamic and calculated by FCEM. After payment, the user
          receives an sbd token, which represents a share of ownership in an offset certificate.
          With each transaction on the FCE Blockchain, sbd tokens accumulate in users&amp; wallets,
          confirming the carbon neutrality of each user and the entire Since FCE Blockchain does not
          charge transaction fees, the CAFFEE for offsetting the carbon footprint of the transaction
          is the only fee in the network.
        </p>
      </Modal>
    </CaFFeeContext.Provider>
  );
};

export function useCaFFee() {
  const context = React.useContext(CaFFeeContext);

  if (!context) {
    throw new Error('`useCaFFee` should be used within a `CaFFeeProvider`');
  }

  return context;
}

export default CaFFeeProvider;
