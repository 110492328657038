import React, { useEffect } from 'react';

const script = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);};
  gtag('js', new Date());
  gtag('config', 'G-NLH7N99HPX');
`;

const GoogleMetrics = () => {
  const isProd = window.location.hostname === 'transparenterra.com';

  useEffect(() => {
    if (isProd) {
      const scriptFirst = document.createElement('script');
      scriptFirst.async = true;
      scriptFirst.src = 'https://www.googletagmanager.com/gtag/js?id=G-NLH7N99HPX';
      document.head.appendChild(scriptFirst);

      const scriptSecond = document.createElement('script');
      scriptSecond.textContent = script;
      document.head.appendChild(scriptSecond);
    }
  }, []);

  return null;
};

export default GoogleMetrics;
