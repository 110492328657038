import React from 'react';
import { MUIModal } from 'tt-ui-kit';
import ModalContainer from '../../components/ModalContainer';
import styles from './KycModal.module.scss';

const YotiModal = ({ isModelOpen, setIsOpen, handleClose, yotiUrl }) => (
  <MUIModal
    onClose={() => handleClose(setIsOpen)}
    open={isModelOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer
      background="rgba(255, 255, 255, 0.4)"
      handleClose={() => handleClose(setIsOpen)}
    >
      {yotiUrl && (
        <iframe
          className={styles.iframe}
          src={yotiUrl}
          allow="camera"
          title="KYC"
          width="400px"
          height="600px"
        />
      )}
    </ModalContainer>
  </MUIModal>
);

export default YotiModal;
