/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import NotificationContext from './notificationContext';

const NotificationState = ({ children }) => {
  const initialState = [];

  const [notificationState, setNotificationState] = useState(initialState);

  return (
    <NotificationContext.Provider
      value={{
        notifications: notificationState,
        setNotifications: setNotificationState,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
