import React, { useEffect, useState } from 'react';
import { Button, Select } from 'tt-ui-kit';
import { roles } from '../../../constants/roles';
import { useWindowSize } from '../../../utils/useWindowSize';

import styles from '../SignInModal.module.scss';

export const SignUpModal = ({ setMode }) => {
  const { width } = useWindowSize();

  const [selectedRole, setSelectedRole] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);
  // TODO: uncomment
  // useEffect(() => {
  //   if (selectedRole) {
  //     setSubmitDisable(false);
  //   } else {
  //     setSubmitDisable(true);
  //   }
  // }, [selectedRole]);

  const submitStep = () => {
    setMode(`signUp_${selectedRole}`);
  };

  const renderSelectValue = (val) => {
    const roleItem = roles.find((item) => item.value === val);
    return roleItem?.name || null;
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.formDescription}>
        <span>
          Book an initial interview and share more information that will help prepare for our
          meeting: your role, experience, expectations, and needs.
        </span>
      </div>
      <form name="role" className={styles.form}>
        <Select
          className={styles.roleSelect}
          label="Role"
          type="default"
          onChange={(e) => setSelectedRole(e.target.value)}
          value={selectedRole}
          renderValue={renderSelectValue}
          items={roles}
        />
      </form>
      <div className={styles.formBottom}>
        <div />
        <Button
          className={styles.formSubmitButton}
          type="primary"
          disabled={submitDisable}
          onClick={() => submitStep()}
        >
          {/* {width > 768 ? 'Send a request' : 'Send'} */}
          Book an interview
        </Button>
      </div>
    </div>
  );
};
