import React, { useEffect, useRef, useState } from 'react';
import styles from './ContactSupportModal.module.scss';
import { useForm } from 'react-hook-form';
import { CloseModalIcon } from './Icons/contactSupportModalCloseIcon';
import { DestroyFileIcon } from './Icons/destroyFileIcon';
import { AddFileIcon } from './Icons/addFileIcon';
import Dialog from '@mui/material/Dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const { REACT_APP_SSO_API } = process.env;
const contactSupportSchema = yup.object().shape({
  fullName: yup.string().min(4).max(255).required(),
  email: yup.string().email().required(),
  subject: yup.string().min(4).max(255).required(),
  request: yup.string().min(4).max(255).required(),
});

const color = '#143471';

const ContactSupportModal = ({ setModalOpen, open, user }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(contactSupportSchema),
    defaultValues: {
      fullName: user?.first_name ? user.first_name + user.last_name : '',
      email: user?.email ? user.email : '',
      subject: '',
      request: '',
    },
  });
  const [filesList, setFilesList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (getValues('fullName') && getValues('email')) {
      setDisabled(true);
    }
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  const refFile = useRef();
  const selectFile = (e) => {
    const neWFiles = [];
    setValue('file', e.target.files[0]);
    let i = filesList.length;
    // eslint-disable-next-line no-restricted-syntax
    for (const file of e.target.files) {
      neWFiles.push({
        id: i,
        name: file.name.split('.')[0],
        type: file.type.split('/')[1],
        size: (file.size * 0.000125).toFixed(2),
      });
      i++;
    }
    setFilesList([...filesList, ...neWFiles]);
  };
  const destroyFile = (id) => {
    setFilesList((current) => current.filter((file) => file.id !== id));
  };
  // const submitContact = (data) => {
  //   window.location = `mailto:${supportMail}?subject=${data.subject}&body=${data.request}`
  // }

  const sendMail = (data) => {
    const formData = new FormData();
    formData.append('subject', data.subject);
    formData.append('body', `fullName:  ${data.fullName}  email: ${data.email}  ${data.body}`);

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    };

    fetch(`${REACT_APP_SSO_API}/email/send`, requestOptions)
      .then(() => setModalOpen(false))
      .catch((error) => console.log('error', error));
  };

  return (
    <Dialog open={open}>
      <div className={styles.contactModal}>
        <div className={styles.modalTitle}>
          <p>Contact Support</p>
          <button type="button" onClick={() => setModalOpen(false)}>
            <CloseModalIcon />
          </button>
        </div>
        <form onSubmit={handleSubmit(sendMail)} autoComplete="off">
          <input type="text" placeholder="Name" disabled={disabled} {...register('fullName')} />
          <input disabled={disabled} type="text" placeholder="Email" {...register('email')} />
          <input type="text" placeholder="Subject" {...register('subject')} />
          <textarea type="text" placeholder="Request" name="request" {...register('request')} />
          <input
            multiple
            type="file"
            {...register('file')}
            hidden
            ref={refFile}
            onChange={selectFile}
          />
          {!!filesList.length && (
            <div className={styles.filesList}>
              {filesList.map((file) => (
                <div className={styles.fileItem} key={file.id}>
                  <p className={styles.fileName}>{file.name}</p>
                  <p className={styles.fileSize}>{file.size} kb</p>
                  <p className={styles.fileType}>{file.type}</p>
                  <button type="button" onClick={() => destroyFile(file.id)}>
                    <DestroyFileIcon />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className={styles.sendFile}>
            <button
              type="button"
              onClick={() => {
                refFile.current.click();
              }}
            >
              <AddFileIcon />
              <span>Add files</span>
            </button>
          </div>
          <div className={styles.submitForm}>
            <button
              type="button"
              style={{ border: `1px solid ${color}`, color: color }}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{
                border: `1px solid ${!isValid ? color : color}`,
                backgroundColor: !isValid ? color : color,
                color: 'white',
              }}
              disabled={!isValid}
            >
              Send Request
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ContactSupportModal;
