import React from 'react';
import { Loader } from 'tt-ui-lib/core';
import styles from './Loader.module.scss';

const DALoader = () => (
  <div
    id="DALoader"
    style={{
      display: 'none',
      zIndex: 10000,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100dvh',
      background: 'rgba(250, 250, 250, 0.8)',
      backdropFilter: 'blur(14px)',
      transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <div
      style={{
        background: '#FFFFFF',
        padding: '24px 40px',
        borderRadius: 12,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 32,
        position: 'relative',
      }}
    >
      <div style={{ fontWeight: 600, fontSize: 24 }}>Confirm the action</div>
      <div className={styles.DAloaderIcon}>
        <Loader />

        <svg
          width="37"
          height="35"
          viewBox="0 0 37 35"
          style={{ zIndex: 2, position: 'absolute', top: 0, left: 0 }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1180_57016)">
            {/* eslint-disable-next-line prettier/prettier */}
            <path d="M36.1419 0L20.2656 11.8532L23.218 4.86777L36.1419 0Z" fill="#E17726" />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M0.894965 0.0141602L13.7844 4.86881L16.5877 11.9458L0.894965 0.0141602ZM29.7534 25.2086L36.7705 25.3434L34.3181 33.7495L25.7556 31.3708L29.7534 25.2086ZM7.24682 25.2086L11.2297 31.3708L2.68166 33.7496L0.244141 25.3434L7.24682 25.2086Z"
              fill="#E27625"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M16.2063 10.1428L16.4931 19.4883L7.91211 19.0944L10.353 15.3788L10.3839 15.3431L16.2063 10.1428ZM20.7046 10.0386L26.6159 15.3434L26.6465 15.379L29.0874 19.0945L20.5083 19.4883L20.7046 10.0386ZM11.4807 25.2354L16.1661 28.919L10.7234 31.5704L11.4807 25.2354ZM25.5199 25.2348L26.2615 31.5706L20.8339 28.9187L25.5199 25.2348Z"
              fill="#E27625"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M20.9538 28.5718L26.4615 31.2627L21.3383 33.7194L21.3915 32.0957L20.9538 28.5718ZM16.0445 28.5729L15.6241 32.0692L15.6586 33.7175L10.5234 31.2627L16.0445 28.5729Z"
              fill="#D5BFB2"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M14.4549 20.708L15.8942 23.76L10.9941 22.3116L14.4549 20.708ZM22.5465 20.7083L26.0237 22.3116L21.1076 23.7596L22.5465 20.7083Z"
              fill="#233447"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M11.8557 25.2044L11.0637 31.7727L6.81836 25.3481L11.8557 25.2044ZM25.1454 25.2046L30.1828 25.3481L25.9216 31.773L25.1454 25.2046ZM29.2119 18.7231L25.5459 22.4931L22.7193 21.1898L21.366 24.0604L20.4789 19.124L29.2119 18.7231ZM7.78701 18.7231L16.5218 19.124L15.6345 24.0604L14.2809 21.1902L11.4694 22.4932L7.78701 18.7231Z"
              fill="#CC6228"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M7.53906 17.9495L11.6868 22.1963L11.8305 26.3889L7.53906 17.9495ZM29.464 17.9419L25.1648 26.3963L25.3267 22.1963L29.464 17.9419ZM16.318 18.2082L16.485 19.2684L16.8975 21.9096L16.6322 30.0216L15.3784 23.505L15.378 23.4376L16.318 18.2082ZM20.6787 18.1935L21.6212 23.4376L21.6207 23.505L20.3638 30.0379L20.314 28.4038L20.1179 21.8615L20.6787 18.1935Z"
              fill="#E27525"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M25.695 22.0273L25.5547 25.67L21.1791 29.1097L20.2946 28.4792L21.2861 23.3261L25.695 22.0273ZM11.3184 22.0273L15.712 23.3261L16.7034 28.4792L15.8189 29.1097L11.4432 25.6697L11.3184 22.0273Z"
              fill="#F5841F"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M9.68555 30.4585L15.2835 33.1348L15.2598 31.9919L15.7283 31.577H21.2687L21.7541 31.9905L21.7182 33.1325L27.2808 30.4652L24.574 32.7221L21.3011 34.9903H15.6833L12.4126 32.7128L9.68555 30.4585Z"
              fill="#C0AC9D"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M20.5534 28.2158L21.3448 28.78L21.8086 32.514L21.1374 31.9422H15.8655L15.207 32.5255L15.6557 28.7803L16.4474 28.2158H20.5534Z"
              fill="#161616"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M35.0942 0.328125L37 6.09685L35.8098 11.9297L36.6573 12.5894L35.5105 13.4722L36.3724 14.1439L35.2311 15.1926L35.9318 15.7046L34.0722 17.8959L26.4453 15.6552L26.3793 15.6195L20.8832 10.9414L35.0942 0.328125ZM1.90579 0.328125L16.117 10.9414L10.6207 15.6195L10.5547 15.6552L2.92777 17.8959L1.06823 15.7046L1.76834 15.1931L0.627699 14.1439L1.48795 13.473L0.323895 12.5876L1.20337 11.9276L0 6.09715L1.90579 0.328125Z"
              fill="#763E1A"
            />
            {/* eslint-disable-next-line prettier/prettier */}
            <path
              d="M26.0729 15.165L34.1541 17.539L36.7795 25.7036H29.853L25.0805 25.7643L28.5513 18.9381L26.0729 15.165ZM10.9283 15.165L8.44946 18.9381L11.9207 25.7643L7.15041 25.7036H0.236328L2.847 17.5392L10.9283 15.165ZM23.6147 4.82861L21.3544 10.9885L20.8747 19.3097L20.6911 21.9179L20.6765 28.5808H16.3245L16.3104 21.9305L16.1262 19.3075L15.6464 10.9885L13.3864 4.82861H23.6147Z"
              fill="#F5841F"
            />
          </g>
          <defs>
            <clipPath id="clip0_1180_57016">
              <rect width="37" height="35" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div style={{ textAlign: 'center', fontSize: 16, lineHeight: '24px', fontWeight: 400 }}>
        You haven&apos;t confirmed the action in MetaMask yet. <br /> Please finalize the process to
        proceed.
      </div>
    </div>
  </div>
);

export default DALoader;
