import React from 'react';
import { Image, Tooltip } from 'tt-ui-kit';

import styles from './Invite.module.scss';

const inviteTooltipText = `Depending on your role, you can invite other users, experts, ESG professional investors, and business owners. Send your invitations to people you know and respect. Inviters are responsible for the members
of the Community they invited. All the invitations have unique numbers to track who sent an invitation and who
was invited. `;

export const InviteWrapper = ({ setInvitionModalVisible }) => (
  <div className={styles.inviteContainer}>
    <div className={styles.inviteHeader}>
      <h3 className={styles.inviteTitle}>Build your Community</h3>
      <div className={styles.inviteImgRow}>
        <Tooltip title={inviteTooltipText} placement="top" arrow>
          <div>
            {/* TODO */}
            <img
              src="https://prod-sso-public-tt.s3.eu-central-1.amazonaws.com/logo/tooltipIcon.svg"
              alt=""
              className={styles.inviteHeaderImg}
            />
          </div>
        </Tooltip>
      </div>
    </div>
    <div className={styles.inviteButton} onClick={() => setInvitionModalVisible(true)}>
      <div className={styles.inviteIMGWrapper}>
        {/* TODO */}
        <img
          src="https://prod-sso-public-tt.s3.eu-central-1.amazonaws.com/logo/add-inv.svg"
          alt=""
          className={styles.inviteIMG}
        />
      </div>
      <button className={styles.openModalButton} type="button">
        Invite Colleague
      </button>
    </div>
  </div>
);
