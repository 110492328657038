import React from 'react';
import { Modal, Button } from 'tt-ui-lib/core';

import styles from './CallTimeoutModal.module.scss';

const CallTimeoutModal = ({ open, handleClose }) => (
  <Modal open={open} onClose={handleClose} title="Call time is out">
    <div className={styles.callTimeoutModal}>
      <div className={styles.modalContent}>
        The call time has expired, you can continue by signing up through the calendar
      </div>

      <div className={styles.modalActions}>
        <Button type="default" onClick={handleClose}>
          BACK TO CHAT
        </Button>

        <Button type="ptimary" disabled>
          GO TO CALENDAR
        </Button>
      </div>
    </div>
  </Modal>
);
export default CallTimeoutModal;
