import React from 'react';

const CheckmarkIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM9 15.875C7.64026 15.875 6.31105 15.4718 5.18046 14.7164C4.04987 13.9609 3.16868 12.8872 2.64833 11.6309C2.12798 10.3747 1.99183 8.99237 2.2571 7.65875C2.52238 6.32513 3.17716 5.10013 4.13864 4.13864C5.10013 3.17716 6.32514 2.52237 7.65876 2.2571C8.99238 1.99183 10.3747 2.12798 11.631 2.64833C12.8872 3.16868 13.9609 4.04987 14.7164 5.18045C15.4718 6.31104 15.875 7.64025 15.875 9C15.8729 10.8227 15.1479 12.5702 13.8591 13.8591C12.5702 15.1479 10.8227 15.8729 9 15.875ZM7.75 6.5V11.5C7.75 11.6658 7.68416 11.8247 7.56695 11.9419C7.44974 12.0592 7.29076 12.125 7.125 12.125C6.95924 12.125 6.80027 12.0592 6.68306 11.9419C6.56585 11.8247 6.5 11.6658 6.5 11.5V6.5C6.5 6.33424 6.56585 6.17527 6.68306 6.05806C6.80027 5.94085 6.95924 5.875 7.125 5.875C7.29076 5.875 7.44974 5.94085 7.56695 6.05806C7.68416 6.17527 7.75 6.33424 7.75 6.5ZM11.5 6.5V11.5C11.5 11.6658 11.4342 11.8247 11.3169 11.9419C11.1997 12.0592 11.0408 12.125 10.875 12.125C10.7092 12.125 10.5503 12.0592 10.4331 11.9419C10.3159 11.8247 10.25 11.6658 10.25 11.5V6.5C10.25 6.33424 10.3159 6.17527 10.4331 6.05806C10.5503 5.94085 10.7092 5.875 10.875 5.875C11.0408 5.875 11.1997 5.94085 11.3169 6.05806C11.4342 6.17527 11.5 6.33424 11.5 6.5Z"
      fill="#757575"
    />
  </svg>
);

export default CheckmarkIcon;
