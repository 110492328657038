/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

/**
 * @typedef TransactionHistory
 * @property {String}  hash
 * @property {String}  ts
 * @property {String}  from
 * @property {?String} to
 * @property {?String} method
 * @property {?String} value
 * @property {?String} value_all
 * @property {String}  blockNumber
 * @property {?String} co2Tokens
 * @property {?String} co2Address
 * @property {?String} co2Cost
 */

import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { useMetaMask } from 'metamask-react';

import {
  chainSettings,
  hideStandby,
  json2obj,
  obj2json,
  showError,
  showInfo,
  showStandby,
  showSuccess,
} from './utils/common';

import {
  APIRequest,
  TTAPIRequest,
  callContractMethod as callContractMethodR,
  callContractMethodWithAddress as callContractMethodWithAddressR,
  callContractMethodWithAddressABI as callContractMethodWithAddressABIR,
  deploy as deployR,
  getContractsDetail as getContractsDetailR,
  sendContractMethod as sendContractMethodR,
  sendContractMethodWithAddress as sendContractMethodWithAddressR,
  sendContractMethodWithAddressABI as sendContractMethodWithAddressABIR,
  sendWithAddress as sendWithAddressR,
  sleep,
  getContractByAddress,
  getContractAddress as getContractAddressR,
} from './utils/request';

import DigitalAssetsContext from './DigitalAssetsContext';
import CaFFeeProvider from './context/CaFFee/CaFFeeProvider';
import WalletProvider from './context/Wallet/WalletProvider';
import WalletOperationProvider from './context/WalletOperations/WalletOperationsProvider';
import BridgeProvider from './context/Bridges/BridgeProvider';
import BlockChainAPIProvider from './context/BlockChainAPI/BlockChainAPIProvider';
import CertificateProvider from './context/Certificate/CertificateProvider';

import {
  fromJSON,
  fromWei,
  toWei,
  hex2num,
  hex2str,
  isAddress,
  isNaN,
  num2hex,
  toBN,
  isBN,
  toJSON,
  int2dateTime,
  dateTime2int,
  dateTime2dateStr,
  dateTime2timeStr,
  dateTime2dateTimeStr,
  str2hex,
  toAscii,
  fromAscii,
  web3Validator,
  sha3,
} from './utils/web3_helper';
import Cookies from 'js-cookie';
import BlockChainTTAPIProvider from './context/BlockChainTTAPI/BlockChainTTAPIProvider';
import DALoader from './loader/DALoader';

const DigitalAssetsProvider = (props) => {
  const { children, userContextObject = null } = props;

  const { status, account, chainId, ethereum, addChain, connect, switchChain } = useMetaMask();

  const [web3, setWeb3] = useState(new Web3(ethereum || window.ethereum));

  const copyToClipboard = (test) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(test).then(
        () => showSuccess('Success copied to clipboard'),
        (err) => showError(`Could not copy text: ${err?.message}`)
      );
    } else {
      showError("Can't copy to clipboard;");
    }
  };

  //-------------------------------------------------------
  // Common

  const [blockNumber, setBlockNumber] = useState(-1n);

  /**
   * Геттер номера текущего блока
   * @return {Promise<BigInt>}
   */
  const getBlockNumber = async () => {
    if (web3) {
      const blckNumber = await web3.eth.getBlockNumber();
      setBlockNumber(blckNumber);
      return blckNumber;
    }
    setBlockNumber(-1n);
    return -1n;
  };

  /**
   * Запрос на смену сети в плагине MetaMask
   * @return {Promise<void>}
   */
  const _switchChain = async (settings) => {
    showStandby();
    try {
      const _set = settings?.chainId ? settings : chainSettings;

      await addChain(_set);
      return switchChain(_set.chainId);
    } catch (err) {
      console.error('switchChain:');
      showError(err?.message);
    } finally {
      hideStandby();
    }
  };

  /**
   * Подключение кошелька к плагину MetaMask
   * @return {Promise<void>}
   * @private
   */
  const _connect = async () => {
    showStandby();
    try {
      await connect();
    } catch (err) {
      showError(`Error while try connecting to MetaMask: ${err?.message}`);
    } finally {
      hideStandby();
    }
  };

  //* ************************************************

  /**
   * Скачивание файла
   * @param {String} URL
   * @param {String} [fileName]
   */
  const downloadFile = (URL, fileName) => {
    const link = document.createElement('a');
    link.href = URL;
    link.setAttribute('download', fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  /**
   * Скачивание файла как PDF
   * @param {String} URL
   * @param {String} [fileName]
   */
  const downloadPDFFile = async (URL, fileName) => {
    const accessToken = Cookies.get('access_token');

    const response = await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'omit',
    });

    if (response && response.ok) {
      const blob = await response.blob();

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));

      return downloadFile(url, fileName);
    }
    showError(response?.statusText);
  };

  /**
   * Вспомогательная функция. Перевод массива в формат csv
   * @param {Array|String} arr
   * @return {String}
   */
  const arrObj2csv = (arr) => {
    if (arr && arr.length > 0) {
      const res = [];
      const fields = Object.keys(arr[0]);
      res.push(fields.join(','));

      arr.forEach((el) => {
        const tmp = [];
        fields.forEach((field) => {
          tmp.push(el[field]);
        });
        res.push(tmp.join(','));
      });

      return res.join('\n');
    }
    return '';
  };

  /**
   * Скачивание массива как CSV
   * @param {String} arr
   * @param {String} [fileName]
   */
  const downloadArrAsCSVFile = (arr, fileName) => {
    const blob = new Blob([arrObj2csv(arr)], { type: 'text/csv' });
    const url = window.URL.createObjectURL(new Blob([blob]));
    return downloadFile(url, fileName);
  };

  // -----------------------

  const getterHash = (val) =>
    val && val.length > 10 ? `${val.substring(0, 5)}...${val.substring(val.length - 3)}` : '-';

  const getterDate = (val) => {
    if (val) {
      const _d = new Date(val);
      // eslint-disable-next-line no-unsafe-optional-chaining
      return `${_d?.getDate()?.toString()?.padStart(2, '0')}.${(_d?.getMonth() + 1)
        ?.toString()
        ?.padStart(2, '0')}.${_d?.getFullYear()?.toString().substring(2)} ${_d
        ?.getHours()
        ?.toString()
        ?.padStart(2, '0')}:${_d?.getMinutes()?.toString()?.padStart(2, '0')}`;
    }
    return '';
  };

  const getterValue = (val) => {
    const _val = parseFloat(val);
    return !isNaN(_val) ? (
      <span title={_val.toString()}>{String(Math.floor(_val * 100000) / 100000)}</span>
    ) : (
      <span>-</span>
    );
  };

  //-------------------------------------------------------

  const callContractMethod = (contract, method, params, bHide) =>
    callContractMethodR(web3, status, account, contract, method, params, bHide).catch((err) =>
      showError(err)
    );
  const callContractMethodWithAddress = (address, contract, method, params, bHide) =>
    callContractMethodWithAddressR(
      web3,
      status,
      account,
      address,
      contract,
      method,
      params,
      bHide
    ).catch((err) => showError(err));
  const callContractMethodWithAddressABI = (address, abi, method, params, bHide) =>
    callContractMethodWithAddressABIR(
      web3,
      status,
      account,
      address,
      abi,
      method,
      params,
      bHide
    ).catch((err) => showError(err));

  const sendContractMethod = (contract, method, params, bHide, amount) =>
    sendContractMethodR(web3, status, account, contract, method, params, bHide, amount).catch(
      (err) => showError(err)
    );
  const sendContractMethodWithAddress = (address, contract, method, params, bHide, amount) =>
    sendContractMethodWithAddressR(
      web3,
      status,
      account,
      address,
      contract,
      method,
      params,
      bHide,
      amount
    ).catch((err) => showError(err));
  const sendContractMethodWithAddressABI = (address, abi, method, params, bHide, amount) =>
    sendContractMethodWithAddressABIR(
      web3,
      status,
      account,
      address,
      abi,
      method,
      params,
      bHide,
      amount
    ).catch((err) => showError(err));

  const sendWithAddress = (address, amount, bHide) =>
    sendWithAddressR(web3, status, account, address, amount, bHide).catch((err) => showError(err));

  const deploy = (abi, bytecode, params, bHide, amount) =>
    deployR(web3, status, account, abi, bytecode, params, bHide, amount).catch((err) =>
      showError(err)
    );

  const getContractsDetail = (name, version, netID, bytecode, last) =>
    getContractsDetailR(name, version, netID || chainId, bytecode, last).catch((err) =>
      showError(err)
    );

  const getContractAddress = (name, netID, version) =>
    getContractAddressR(name, netID || chainId, version).catch((err) => showError(err));

  //-------------------------------------------------------

  useEffect(() => {
    getBlockNumber().then();
    const handler = setInterval(getBlockNumber, 12000);
    return () => clearInterval(handler);
  }, [chainId]);

  useEffect(() => {
    console.info(
      `DigitalAssetsProvider: CREATE WEB3! ethereum: ${!!ethereum}; window.ethereum: ${!!window.ethereum}`
    );
    setWeb3(ethereum ? new Web3(ethereum || window.ethereum) : null);
  }, []);

  useEffect(() => {
    console.info(
      `DigitalAssetsProvider: CHANGES ethereum: ${!!ethereum}; window.ethereum: ${!!window.ethereum}`
    );
    setWeb3(ethereum ? new Web3(ethereum || window.ethereum) : null);
  }, [ethereum]);

  //-------------------------------------------------------

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const defaultValue = {
    chainSettings: chainSettings,
    web3: web3,

    status,
    account,
    chainId,
    switchChain: _switchChain,
    connect: _connect,

    blockNumber,
    getBlockNumber,

    copyToClipboard,
    downloadFile,
    downloadPDFFile,
    downloadArrAsCSVFile,
    arrObj2csv,

    getterHash,
    getterDate,
    getterValue,

    getContractsDetail,
    getContractByAddress,
    getContractAddress,

    APIRequest,
    TTAPIRequest,

    deploy,

    callContractMethodWithAddressABI,
    callContractMethodWithAddress,
    callContractMethod,

    sendContractMethodWithAddressABI,
    sendContractMethodWithAddress,
    sendWithAddress,
    sendContractMethod,

    toBN,
    isBN,
    isNaN,
    fromWei,
    toWei,
    hex2num,
    num2hex,
    hex2str,
    str2hex,
    fromAscii,
    toAscii,
    isAddress,
    sha3,
    web3Validator,

    int2dateTime,
    dateTime2int,
    dateTime2dateStr,
    dateTime2timeStr,
    dateTime2dateTimeStr,

    sleep,

    obj2json,
    json2obj,

    showSuccess,
    showError,
    showInfo,
    showStandby,
    hideStandby,
  };

  //-------------------------------------------------------

  return (
    <DigitalAssetsContext.Provider value={defaultValue}>
      <CaFFeeProvider web3={web3} setWeb3={setWeb3}>
        <WalletProvider web3={web3} setWeb3={setWeb3} userContextObject={userContextObject}>
          <WalletOperationProvider web3={web3} setWeb3={setWeb3}>
            <BridgeProvider web3={web3} setWeb3={setWeb3}>
              <BlockChainAPIProvider web3={web3} setWeb3={setWeb3}>
                <BlockChainTTAPIProvider web3={web3} setWeb3={setWeb3}>
                  <CertificateProvider web3={web3} setWeb3={setWeb3}>
                    {children}
                  </CertificateProvider>
                </BlockChainTTAPIProvider>
              </BlockChainAPIProvider>
            </BridgeProvider>
          </WalletOperationProvider>
        </WalletProvider>
      </CaFFeeProvider>

      <DALoader />
    </DigitalAssetsContext.Provider>
  );
};

export function useDigitalAssets() {
  const context = React.useContext(DigitalAssetsContext);

  if (!context) {
    throw new Error('`useDigitalAssets` should be used within a `DigitalAssetsProvider`');
  }

  return context;
}

export default DigitalAssetsProvider;

export { toJSON, fromJSON };
