import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { Button, HelpTooltip, PartInput } from 'tt-ui-kit';
import { useWindowSize } from '../../../utils/useWindowSize';

import styles from '../SignInModal.module.scss';

const schema = yup.object({
  code: yup.string().required('Please enter your invite code'),
});

export const SignUpRefModal = ({ setMode, handler }) => {
  const { width } = useWindowSize();

  const [error, setError] = useState('');
  const [refCode, setRefCode] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  useEffect(() => {
    if (refCode) {
      setValue('code', refCode);
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [refCode]);

  const onSubmit = async (data) => {
    setError('');
    setLoading(true);

    await handler(data, setError, () => {
      setLoading(false);
    });
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.formDescriptionRow}>
        <span>
          Please add your invitation code if you have another role on Transparenterra than
          Individual
        </span>
        <HelpTooltip
          tooltipText={
            <span>
              TransparenTerra is a multifunctional professional platform built on invitations. There
              are 4 roles on TransparenTerra: Expert, Investor, Business, and Individual. Depending
              on the user&apos;s role, different features and opportunities are provided.
              <br />
              <br />
              If you already have an invitation code, please add it here. If not, you can register
              as an Individual role right now and start using the platform.
            </span>
          }
        />
      </div>
      <form name="login" method="POST" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputWrapper}>
          <PartInput code={refCode} setCode={setRefCode} cellClassName={styles.partInput} />
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.codeDesc}>
            If you don&apos;t have an invitation code, you can still sign up as an Individual, which
            is the quickest way to get started. However, please note that Individuals have limited
            access to our platforms features.
            <br />
            <br />
            Alternatively, if you&apos;re an Expert, Investor, or Business, you can book an initial
            interview to receive an invitation.
          </div>

          <div className={styles.links}>
            <Button
              type="link"
              className={styles.formSecondaryButton}
              onClick={() => setMode('signUp_individual')}
            >
              Sign up as an Individual
            </Button>

            <Button
              type="link"
              className={styles.formSecondaryButton}
              onClick={() => setMode('up')}
            >
              Book an interview
            </Button>
          </div>
        </div>
        {!errors.code?.message && error && (
          <div className={styles.formError}>
            {error.trim().includes('Validation failed') ? (
              <p>• The code you entered is incorrect. Please try again.</p>
            ) : (
              <p>• Server Error. Try again later.</p>
            )}
          </div>
        )}
        <div className={styles.formBottom}>
          {/* <Button */}
          {/*  className={styles.formSecondaryButton} */}
          {/*  text="" */}
          {/*  onClick={() => setMode('signUp_individual')} */}
          {/* > */}
          {/*  {width > 768 ? 'I don’t have an invite' : 'Cancel'} */}
          {/* </Button> */}
          <div />
          <Button
            className={styles.formSubmitButton}
            type="primary"
            disabled={submitDisable}
            loading={loading}
            isSubmit
          >
            Apply code
          </Button>
        </div>
      </form>
    </div>
  );
};
