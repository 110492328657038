import axios from 'axios';
import Cookies from 'js-cookie';

export const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const axiosBlockchainClient = async (url, data, headers, config) =>
  axiosClient.post(url, data, {
    headers: {
      ...headers,
      authorization: 'Basic YWRtaW46Z2ZoamttMDE=',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
    },
    ...config,
  });

export const axiosSSOConfigPrivate = (headers, options) => {
  const accessToken = Cookies.get('access_token');

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    ...options,
  };
};

export const axiosQAConfigPrivate = async (headers, options) => {
  const accessToken = process.env.REACT_APP_CONCIERGE_SECRETS;

  return {
    headers: {
      Authorization: `${accessToken}`,
      ...headers,
    },
    ...options,
  };
};
