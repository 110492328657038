import React from 'react';
import styles from './MobileNavbar.module.scss';
import NavbarLink from './NavbarLink';
import {
  HomeIcon,
  PricingIcon,
  OrdersIcon,
  CommunityIcon,
  MessageIcon,
  GridViewIcon,
  CalendarIcon,
  CalculatorIcon,
  BookmarkIcon,
  MenuItem,
} from 'tt-ui-kit';

import { ReactComponent as GridActiveIcon } from '../../assets/svg/navbar/grid-icon-blue.svg';
import { ReactComponent as PlusGradientIcon } from '../../assets/svg/navbar/plus-gradient-icon.svg';

export const MobileNavbarWrapper = ({
  user,
  setAnchorLoginEl,
  redirectDomain,
  createAnInvitation,
  openCalculator,
  openCalendar,
  openBookmark,
  marketTTUrl,
  digitalAssetsTTUrl,
  qaTTUrl,
}) => {
  const handleLoginClick = (event) => {
    event.preventDefault();
    setAnchorLoginEl(event.currentTarget);

    event.stopPropagation();
  };

  return (
    <div className={styles.mobileNavbar}>
      <NavbarLink
        href={redirectDomain ? `${redirectDomain}/dashboard/feed` : '/dashboard/feed'}
        Icon={HomeIcon}
      />
      <NavbarLink
        href={redirectDomain ? `${redirectDomain}/dashboard/pricing` : '/dashboard/pricing'}
        Icon={PricingIcon}
      />
      <NavbarLink
        href={redirectDomain ? `${redirectDomain}/dashboard/community` : '/dashboard/community'}
        Icon={CommunityIcon}
        onClick={!user.id ? (e) => handleLoginClick(e) : null}
      />
      <NavbarLink
        href={marketTTUrl ? `${marketTTUrl}/dashboard/orders` : '/dashboard/orders'}
        Icon={OrdersIcon}
        onClick={!user.id ? (e) => handleLoginClick(e) : null}
      />
      <NavbarLink
        href={redirectDomain ? `${redirectDomain}/dashboard/messaging` : '/dashboard/messaging'}
        Icon={MessageIcon}
        onClick={!user.id ? (e) => handleLoginClick(e) : null}
      />
      <NavbarLink
        itemIsMenu
        Icon={GridViewIcon}
        IconActive={GridActiveIcon}
        menuItems={
          <>
            {user?.kyc === 'success' ||
            (user?.roles_list && JSON.parse(user?.roles_list).includes('transparenterra')) ? (
              <MenuItem onClick={() => createAnInvitation()} className={styles.menuItem}>
                <PlusGradientIcon style={{ width: 20, height: 20 }} />
              </MenuItem>
            ) : null}
            <MenuItem onClick={() => openCalendar()} className={styles.menuItem}>
              <CalendarIcon style={{ width: 20, height: 20 }} />
            </MenuItem>
            <MenuItem onClick={() => openCalculator()} className={styles.menuItem}>
              <CalculatorIcon style={{ width: 20, height: 20 }} />
            </MenuItem>
            <MenuItem onClick={() => openBookmark()} className={styles.menuItem}>
              <BookmarkIcon style={{ width: 20, height: 20 }} />
            </MenuItem>
          </>
        }
        onClick={!user.id ? (e) => handleLoginClick(e) : null}
      />
    </div>
  );
};
