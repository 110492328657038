import React from 'react';

import styles from './LoaderAfterDOM.module.scss';

const LoaderAfterDOM = (props) => {
  const { back, initHide, message, id } = props;

  if (back) {
    return (
      <div id={id || 'loadingsScreen'} className={styles.container}>
        <div className={styles.centerContainer}>
          <div className={styles.logo} />
          <div className={styles.loading}>Loading...</div>
        </div>
      </div>
    );
  }
  return (
    <div
      id={id || 'loadingsScreen'}
      className={styles.container}
      style={{ display: initHide ? 'none' : '' }}
    >
      <div className={styles.centerContainer}>
        <div className={styles.logo} />
        <div className={styles.loading}>{message || 'Loading...'}</div>
      </div>
    </div>
  );
};

export default LoaderAfterDOM;
