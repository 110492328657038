import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { location } = useLocation();

  useEffect(() => {
    const scrollElement = document.getElementById('imageBg');
    if (scrollElement) {
      scrollElement.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [location]);

  return null;
};

export default ScrollToTop;
