import React from 'react';
import { Image, Button } from 'tt-ui-kit'; // TODO: remove

import WatchIconSVG from '../assets/watch-icon.svg';
import ResizeFullIconSVG from '../assets/resize-full-icon.svg';
import ResizeSmallIconSVG from '../assets/resize-small-icon.svg';

import styles from './Header.module.scss';

const Header = ({ size, status, changeSize, timer }) => {
  const handleChangeSize = async () => {
    const newSize = size === 'xl' ? 'xs' : 'xl';
    changeSize(newSize);
  };

  return (
    <div className={styles.dialogHeader}>
      {status === 'answered' && size === 'xs' && (
        <div className={styles.timer}>
          <Image src={WatchIconSVG} width={20} height={20} />
          <span>{timer}</span>
        </div>
      )}
      <p className={styles.dialogTitle}>Meet “WebOnline”</p>
      <div className={styles.dialogResizer}>
        <Button type="icon" onClick={() => handleChangeSize('xl')}>
          {size === 'xs' ? (
            <Image src={ResizeFullIconSVG} width={16} height={16} />
          ) : (
            <Image src={ResizeSmallIconSVG} width={16} height={16} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default Header;
