import React, { useContext } from 'react';
import CertificateContext from './CertificateContext';
import { sendContractMethod } from '../../utils/request';
import { toBN, toWei } from '../../utils/web3_helper';
import { useCaFFee } from '../CaFFee/CaFFeeProvider';
import { useMetaMask } from 'metamask-react';

const CertificateProvider = (props) => {
  const { status } = useMetaMask();
  const { children, web3 } = props;
  const { getCO2TokenCost } = useCaFFee();

  /**
   * Async function to move a certificate from user's wallet.
   * @param {Object} params - The parameters for the certificate move.
   * @param {string} params.uid - The credit UID.
   * @param {string} params.account - The wallet address.
   * @returns {Promise<any>} - A promise that resolves with the result of the order.
   */
  const certificateOffset = async (params, bHide = false) => {
    const transactionPayload = {
      contractAddress: params.contractAddress,
      account: [params.account],
      amount: [params.amount],
    };

    return sendContractMethod(
      web3,
      status,
      params.account,
      'CarbonCreditContract',
      'offsetToAddress',
      [transactionPayload.account, transactionPayload.amount],
      bHide,
      undefined,
      undefined,
      transactionPayload.contractAddress
    );
  };

  /**
   * Async function to move a certificate from user's wallet.
   * @param {Object} params - The parameters for the certificate move.
   * @param {string} params.uid - The credit UID.
   * @param {string} params.account - The wallet address.
   * @returns {Promise<any>} - A promise that resolves with the result of the order.
   */
  const certificateDetachWallet = async (params, bHide = false) => {
    const transactionPayload = {
      account: params.account,
      contractAddress: params.contractAddress,
    };

    return sendContractMethod(
      web3,
      status,
      params.account,
      'CarbonCreditContract',
      'detachMyself',
      undefined,
      bHide,
      undefined,
      undefined,
      transactionPayload.contractAddress
    );
  };

  /**
   * Async function to move a certificate to user's wallet.
   * @param {Object} params - The parameters for the certificate move.
   * @param {string} params.uid - The credit UID.
   * @param {string} params.account - The wallet address.
   * @returns {Promise<any>} - A promise that resolves with the result of the order.
   */
  const certificateMoveToWallet = async (params, bHide = false) => {
    const transactionPayload = {
      uid: [params.uid],
      account: [params.account],
    };

    return sendContractMethod(
      web3,
      status,
      params.account,
      'CarbonCreditContract',
      'snapToAddress',
      [transactionPayload.uid, transactionPayload.account],
      bHide
    );
  };

  /**
   * Async function to order a certificate deposit.
   * @param {Object} params - The parameters for the certificate order.
   * @param {string} params.id - The ID of the certificate.
   * @param {string} params.creator - The creator of the certificate.
   * @param {string} params.creatorUid - The ID of the certificate creator.
   * @param {string} params.creditAmount - The credit amount for the certificate.
   * @param {number} params.amount - The amount for the certificate order.
   * @param {boolean} [bHide=false] - Whether to hide the order.
   * @returns {Promise<any>} - A promise that resolves with the result of the order.
   */
  const certificateOrderDeposit = async (params, bHide = false) => {
    const CaFFee = await getCO2TokenCost();
    const fullAmount = toWei(params.amount || 0) + toBN(CaFFee || 0);

    const transactionPayload = {
      account: params.creator,
      params: [params.id, params.creatorUid, params.creator, params.creditAmount],
      amount: fullAmount,
    };

    return sendContractMethod(
      web3,
      status,
      transactionPayload.account,
      'CarbonOffsetFactory',
      'orderCertificate',
      transactionPayload.params,
      bHide,
      transactionPayload.amount
    );
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const defaultValue = {
    certificateOrderDeposit,
    certificateMoveToWallet,
    certificateDetachWallet,
    certificateOffset,
  };

  return <CertificateContext.Provider value={defaultValue}>{children}</CertificateContext.Provider>;
};

export const useCertificate = () => {
  const context = useContext(CertificateContext);

  if (!context) {
    throw new Error('`useCertificate` should be used within a `CertificateProvider`');
  }

  return context;
};

export default CertificateProvider;
