import React from 'react';
import clsx from 'clsx';

import FullScreenIconSVG from '../../assets/images/fullscreen.svg';
import CloseIconSVG from '../../assets/images/close-icon-grey.svg';

import styles from './ModalContainer.module.scss';

const ModalContainer = ({
  background,
  handleClose,
  children,
  maxWidth,
  isMapContainer,
  isConsentModal,
  isErrorModal,
  isOrientationModal,
  isFullScreen,
  isFullScreenActive,
  setIsFullScreenActive,
  ...restProps
}) => (
  <div
    className={clsx(
      styles.modalContainer,
      isMapContainer ? styles.mapContainer : '',
      isConsentModal ? styles.consentContainer : '',
      isErrorModal ? styles.errorModalContainer : '',
      isFullScreen ? styles.fullScreen : '',
      isFullScreenActive ? styles.fullScreenActive : '',
      restProps ? restProps.className : ''
    )}
    style={{ background, maxWidth, overflow: restProps?.isScroll ? 'auto' : 'unset' }}
  >
    {isConsentModal && (
      <div className={styles.iconsWrapper}>
        {isFullScreen && (
          <div
            className={styles.fullscreenIconWrapper}
            onClick={() => setIsFullScreenActive(!isFullScreenActive)}
            aria-hidden
          >
            <img src={FullScreenIconSVG} alt="" />
          </div>
        )}
        <div className={styles.closeIconWrapper} onClick={handleClose} aria-hidden>
          <img src={CloseIconSVG} alt="" />
        </div>
      </div>
    )}
    {(isErrorModal || restProps?.isPasswordUpdateModal) && !isOrientationModal && (
      <div
        className={clsx(
          isErrorModal ? styles.errorCloseIconWrapper : '',
          restProps?.isPasswordUpdateModal ? styles.passwordCloseIconWrapper : ''
        )}
        onClick={handleClose}
        aria-hidden
      >
        <img src={CloseIconSVG} alt="" />
      </div>
    )}
    {children}
  </div>
);

export default ModalContainer;
