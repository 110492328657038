import React, { useState } from 'react';
import styles from './Decarbonizator.module.scss';
import { Input, Button, Table, Space } from 'tt-ui-lib/core';
import { SearchIcon, FilterIcon } from 'tt-ui-lib/icons';

const DecarbonizatorAdminPage = () => {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState([]);
  const [searchString, setSearchString] = useState('');

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Company Name',
    },
    {
      dataIndex: 'date',
      key: 'date',
      title: 'Date of connection',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
    },
    {
      dataIndex: 'payment',
      key: 'payment',
      title: 'Payment',
    },
    {
      dataIndex: 'plan',
      key: 'plan',
      title: 'Plan',
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      render: () => (
        <Space>
          <Button type="primary">Connect</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <div className={styles.searchRow}>
        <Input
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Search"
          prefix={<SearchIcon style={{ width: 20, height: 20 }} />}
          style={{ width: '100%' }}
        />
        <Button type="icon" icon={<FilterIcon style={{ width: 20, height: 20 }} />} />
      </div>

      <Table columns={columns} data={tableData} />
    </div>
  );
};

export default DecarbonizatorAdminPage;
