import { useContext } from 'react';
import { allContexts } from './index';

export const useSelectedContext = (selectCallback) => {
  const selectedContext = selectCallback(allContexts);
  const context = useContext(selectedContext);

  if (!context) {
    throw new Error('useSelectedContext must be used within context select callback');
  }

  return context;
};
