import React, { useEffect, useState, useRef } from 'react';
import styles from './ApiIntegration.module.scss';

import { useSelectedContext } from 'context/contextSelector';
import UnauthApi from './components/UnauthApi';
import UnauthDocumentation from './components/UnauthDocumentation';
import AuthApi from './components/AuthApi';
import DecarbonizatorBlock from 'components/DecarbonizatorBlock';
import ApiDocumentation from 'components/ApiDocumentation';

const ApiIntegration = ({ containerRef }) => {
  const { user } = useSelectedContext((context) => context.user);

  const [documentationTab, setDocumentationTab] = useState(false);

  const openDocumentation = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
      });
    }
    setDocumentationTab(!documentationTab);
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {documentationTab ? (
          <UnauthDocumentation openDocumentation={openDocumentation} />
        ) : (
          <UnauthApi openDocumentation={openDocumentation} bookbutton />
        )}
        {/* {user?.id ? (
          documentationTab ? (
            <>
              <DecarbonizatorBlock openDocumentation={openDocumentation} />
              <ApiDocumentation />
            </>
          ) : (
            <AuthApi openDocumentation={openDocumentation} />
          )
        ) : documentationTab ? (
          <UnauthDocumentation openDocumentation={openDocumentation} />
        ) : (
          <UnauthApi openDocumentation={openDocumentation} bookbutton />
        )} */}
      </div>
    </div>
  );
};

export default ApiIntegration;
