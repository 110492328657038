import React, { useState } from 'react';
import { Avatar, ButtonBase, LoaderOn, AddPhotoIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import styles from './Uploader.module.scss';
import AvatarCropModal from '../../AvatarCropModal/AvatarCropModal';
import { GET_URL_FOR_PUBLIC_UPLOAD } from '../../../constants/api';

const AvatarSignupUploader = ({ avatarSrc, changeAvatar, error, helperText }) => {
  const [avatar, setAvatar] = useState(null);
  const [fileToS3, setFileToS3] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [isAvatarModal, setIsAvatarModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [getPublicUrl] = useMutation(GET_URL_FOR_PUBLIC_UPLOAD, {
    variables: { input: { fileToS3 } },
  });

  const uploadFile = async (file) => {
    try {
      setIsLoading(true);

      const formatFile = file?.name.substr(-4, 4);
      let filesName;
      if (formatFile === '.') {
        filesName = `${uuidv1()}.${file?.name}${formatFile}`;
      } else {
        filesName = `${uuidv1()}.${file?.name}`;
      }
      const path = `avatars`;
      const toUpload = { path: path, name: filesName };
      setFileToS3(toUpload);
      const { data } = await getPublicUrl({ variables: { input: toUpload } });
      setFileToS3(null);
      const urlToUpload = data?.getUrlForPublicUpload?.url;

      const putData = await axios.put(urlToUpload, file, {
        headers: {
          'Content-type': file.type,
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (putData.statusText === 'OK') {
        changeAvatar(`${toUpload.name}`);
      } else {
        openNotification({
          notification: 'Error while uploading file.',
          type: 'error',
        });
      }

      setIsLoading(false);
    } catch (e) {
      openNotification({
        notification: 'Error while uploading file.',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  const handleUpdateAvatar = async (file) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    setAvatar(file);
    await uploadFile(file);
  };

  const openCropModal = (e) => {
    const file = e.target.files[0];
    const filesize = (file.size / 1024 / 1024).toFixed(2);

    if (filesize <= 2) {
      setAvatarFile(file);
      setIsAvatarModal(true);
    } else {
      openNotification({
        notification: 'Allowed files with 2MB or below.',
        type: 'info',
      });
    }
  };

  return (
    <>
      <div className={styles.avaDiv}>
        <Avatar
          src={avatar?.preview || avatarSrc}
          sx={{ width: 128, height: 128 }}
          className={error ? styles.errorAvatar : ''}
        />
        <ButtonBase className={styles.avaButton} variant="contained" component="label">
          <AddPhotoIcon style={{ width: 20, height: 20 }} />
          <input onChange={openCropModal} type="file" hidden accept=".png, .jpg, .jpeg" />
        </ButtonBase>
        {isLoading && <LoaderOn />}
      </div>
      {helperText && <div className={styles.errorMessage}>{helperText}</div>}
      {isAvatarModal && (
        <AvatarCropModal
          open={isAvatarModal}
          setOpen={setIsAvatarModal}
          avatarFile={avatarFile}
          setAvatar={handleUpdateAvatar}
        />
      )}
    </>
  );
};

export default AvatarSignupUploader;
