import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-lib/core';

export const openAppLoader = (opacity) => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = opacity ?? '1';
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = '0';
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod, withRedirect = true) => {
  openAppLoader();
  // eslint-disable-next-line no-unused-expressions
  (logoutApiMethod &&
    logoutApiMethod()
      .then((response) => {
        if (response?.data?.logout) {
          const domainValue = process.env.REACT_APP_DOMAIN;
          Cookies.remove('access_token', { domain: domainValue });
          Cookies.remove('userId', { domain: domainValue });
          Cookies.remove('calculatorId', { domain: domainValue });
          Cookies.remove('companyId');
          Cookies.remove('last_active', {
            domain: domainValue,
          });
          localStorage.removeItem('currentCompanyData');
          // eslint-disable-next-line no-unused-expressions
          disconnectSocketMethod && disconnectSocketMethod();

          if (withRedirect) {
            window.location.href = '/';
          } else {
            window.location.reload();
          }
        } else {
          closeAppLoader();
        }
      })
      .catch(() => {
        closeAppLoader();
        const domainValue = process.env.REACT_APP_DOMAIN;
        Cookies.remove('access_token', { domain: domainValue });
        Cookies.remove('userId', { domain: domainValue });
        Cookies.remove('calculatorId', { domain: domainValue });
        Cookies.remove('companyId');
        Cookies.remove('last_active', {
          domain: domainValue,
        });
        localStorage.removeItem('currentCompanyData');
        // eslint-disable-next-line no-unused-expressions
        disconnectSocketMethod && disconnectSocketMethod();

        if (withRedirect) {
          window.location.href = '/';
        } else {
          window.location.reload();
        }
      })) ||
    closeAppLoader();
};

export const showError = (msg) =>
  openNotification({
    message: msg?.message || msg,
    type: 'error',
  });
