import { useState } from 'react';
import clsx from 'clsx';
import { Image, Button, InputBase, Paper } from 'tt-ui-kit'; // TODO: remove
import ListMember from './ListMember';

import CloseIconSVG from '../../../assets/images/close.svg';
import ChatSearchSVG from '../../../assets/images/search.svg';
import AvatarSVG from '../../../assets/images/avatar.svg';

import styles from './AddMembersMenu.module.scss';

const TEST_DATA = [
  {
    id: 1,
    name: 'Devon Lane',
    avatar: AvatarSVG,
    status: 'online',
  },
  {
    id: 2,
    name: 'Dianne Russell',
    avatar: AvatarSVG,
    status: 'busy',
  },
  {
    id: 3,
    name: 'Darlene Robertson',
    avatar: AvatarSVG,
    status: 'offline',
  },
];

const AddMembersMenu = ({ open, onClose }) => {
  const [members, setMembers] = useState(TEST_DATA);
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className={clsx(styles.menuWrapper, open ? styles.visible : '')}>
      <div className={styles.menuHeader}>
        <div className={styles.title}>Add members</div>
        <Button type="icon" onClick={() => onClose()}>
          <Image className={styles.close} src={CloseIconSVG} width={14} height={14} />
        </Button>
      </div>
      <Paper component="form" className={styles.searchInputContent}>
        <Button type="icon" sx={{ p: '10px' }} aria-label="search">
          <Image src={ChatSearchSVG} width={17} height={17} />
        </Button>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={searchValue}
          placeholder="Enter name..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Paper>
      <div className={styles.membersList}>
        {members.map((member) => (
          <ListMember memberData={member} key={member.id} />
        ))}
      </div>
    </div>
  );
};

export default AddMembersMenu;
