import React from 'react';
import styles from './UnauthDocumentation.module.scss';

import { LeftIcon } from 'tt-ui-kit';
import { Button } from 'tt-ui-lib/core';

import benefitsIconOne from '../../../../assets/images/benefitsIconOne.svg';
import benefitsIconTwo from '../../../../assets/images/benefitsIconTwo.svg';
import benefitsIconThree from '../../../../assets/images/benefitsIconThree.svg';
import benefitsIconFour from '../../../../assets/images/benefitsIconFour.svg';
import decarbonizatorImage from '../../../../assets/images/decarbonizatorImage.png';

import QuestionItem from 'components/QuestionItem/indes';

const questionsData = [
  {
    question: 'What is TT Decarbonizator, and why do you need it?',
    answer:
      'Decarbonizator is a tool that allows online marketplaces, apps, and ERP systems to reward their end users and B2B customers with carbon offsets of their e-transactions. Decarbonizator may also calculate and offset the CO2 emissions relating to the delivery process. Implementing both functions allows you to achieve carbon neutrality for your online business processes and share your sustainability championship with your customers through carbon offsets.',
  },
  {
    question:
      'How is TT Decarbonizator different from other apps offering carbon-neutral shopping?',
    answer: 'Decarbonizator is different in 3 ways:',
    enumeration: [
      'The first difference is the fundamental one. When you buy the tokenized carbon credit from us to fraction it and offer it to your customers, it is already offset, meaning that we have already fully paid it and thus made a real investment into a certain impact environmental project. This means that your money is not going to be invested, but it has already been sent to the real project—the issuer of the carbon credit you are purchasing.',
      'When your customers offset their shopping transactions’ carbon footprint, they immediately receive an offset certificate traceable on the blockchain to show (a) the exact environmental project their money was invested in and the project’s credentials and accreditations and (b) how it can be deployed in their sustainability reports, if required.',
      'While most other apps offer carbon offsets related to shipping (i.e., to counterbalance the carbon dioxide emitted by vehicles in the process of transporting an order from a seller to a buyer), we offer not just that but also compensation for the greenhouse gas (GHG) emitted by the e-transaction itself made on the website (i.e., while a customer is choosing a product, placing it in the cart, and making a payment). This turns online shopping from half to fully net zero!',
    ],
  },
  {
    question: 'What are your direct benefits of using TT Decarbonizator?',
    enumeration: [
      'You become a pioneer in a truly net-zero online economy with Decarbonizator and stand out among other e-trading platforms.',
      'You can integrate your customers’ transactions offsets into your supply chain sustainability reporting.',
      'You grow your customer loyalty by engaging them in the real environmental impact.',
      'You make your business truly sustainable and transparent by ensuring that sustainability is built into the core of the processes rather than being an afterthought.',
    ],
  },
  {
    question: 'How can I delete my API key?',
    answer: 'Deleting your API key is easy. Select the ‘Delete Key’ button on the API tool page.',
  },
];

const UnauthDocumentation = ({ openDocumentation }) => (
  <div>
    <div className={styles.decarbonizatorBlock}>
      <div className={styles.decarbonizatorInfo}>
        <div className={styles.decarbonizatorTitle}>
          <LeftIcon onClick={openDocumentation} style={{ width: 30 }} />
          <h2>Decarbonizator</h2>
        </div>
        <p>
          Decarbonizator is designed to offset the carbon emissions from your daily business
          transactions by allocating tokenized carbon credits, each precisely representing the
          emissions from an individual transaction. We will conduct thorough calculations based on
          your specific data to ensure accuracy. When completing a transaction, your customer gets
          an instant eco-reward, an offset certificate. Each certificate is issued by a reputable
          accredited environmental project and rigorously validated. The certificate is fully
          traceable on our cutting-edge Net-Zero FCEchain, providing seamless integration into your
          customer’s sustainability report as required.
        </p>
        <p>
          Additionally, we can provide an optional service to calculate and offset your delivery
          process&apos;s carbon footprint, further enhancing your commitment to sustainability.
        </p>
      </div>
      <img className={styles.decarbonizatorImage} src={decarbonizatorImage} alt="" width={430} />
    </div>
    <div className={styles.benefitsOfDecarbonizer}>
      <h3>By choosing Decarbonizator, you will enhance:</h3>
      <div className={styles.benefitsList}>
        <div className={styles.benefitsListRow}>
          <div className={styles.benefitsListItem} style={{ marginRight: 50 }}>
            <img src={benefitsIconOne} alt="" />
            <div>
              <p>Brand&apos;s reputation</p>
              <p>
                Elevate your brand&apos;s reputation
                <br /> as an environmental leader
              </p>
            </div>
          </div>
          <div className={styles.benefitsListItem}>
            <img src={benefitsIconTwo} alt="" />
            <div>
              <p>Competitive edge</p>
              <p>
                Secure a competitive edge by
                <br /> delivering a net-zero transaction
                <br /> experience
              </p>
            </div>
          </div>
        </div>
        <div className={styles.benefitsListRow}>
          <div className={styles.benefitsListItem}>
            <img src={benefitsIconThree} alt="" />
            <div>
              <p>Sustainability & transparency</p>
              <p>
                Boost your business&apos;s sustainability
                <br /> and operational transparency
              </p>
            </div>
          </div>
          <div className={styles.benefitsListItem}>
            <img src={benefitsIconFour} alt="" />
            <div>
              <p>Customer loyalty</p>
              <p>
                Strengthen customer loyalty with
                <br /> meaningful engagement
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.documentationBlock}>
      <h2>
        Make the strategic move to integrate Decarbonizator into your platform today and join the
        forefront of eco-conscious commerce.
      </h2>
      <Button type="primary" className={styles.whiteButton}>
        View API documantation
      </Button>
    </div>
    <div className={styles.questionsBlock}>
      <h2>Q&A</h2>
      <div className={styles.questionsList}>
        {questionsData.map((item) => (
          <QuestionItem item={item} />
        ))}
      </div>
    </div>
  </div>
);

export default UnauthDocumentation;
