import React, { useEffect, useState } from 'react';
import { Modal } from 'tt-ui-kit';
import { SignInModal } from './forms/SignInModal';
import { SignUpModal } from './forms/SignUpModal';
import { SignUpRefModal } from './forms/SignUpRefModal';
import { ResetModal } from './forms/ResetModal';
import IndividualForm from './forms/rolesForms/Individual';
import InvestorForm from './forms/rolesForms/Investor';
import BusinessForm from './forms/rolesForms/Business';
import ExpertForm from './forms/rolesForms/Expert';
import { GET_AGREEMENT } from '../../constants/api';

const SectionsSwitcher = ({
  mode,
  setMode,
  setTitle,
  closeModal,
  loginHandler,
  signUpHandler,
  signUpRefHandler,
  agreementSchema,
  signInWithGoogle,
}) => {
  switch (mode) {
    case 'in':
      setTitle('Please sign in to proceed');
      return (
        <SignInModal
          signInWithGoogle={signInWithGoogle}
          setMode={setMode}
          closeModal={closeModal}
          handler={loginHandler}
        />
      );
    case 'reset':
      setTitle('Reset password');
      return <ResetModal setMode={setMode} />;
    case 'up-ref':
      setTitle('Sign up');
      return (
        <SignUpRefModal setMode={setMode} closeModal={closeModal} handler={signUpRefHandler} />
      );
    case 'up':
      setTitle('Book an initial interview');
      return (
        <SignUpModal setMode={setMode} closeModal={closeModal} agreementSchema={agreementSchema} />
      );

    case 'signUp_individual':
      setTitle('Sign Up as an Individual');
      return (
        <IndividualForm
          setMode={setMode}
          agreementSchema={GET_AGREEMENT}
          handler={signUpHandler}
          closeModal={closeModal}
          signUpWithGoogle={signInWithGoogle}
        />
      );

    case 'signUp_investor':
      setTitle('Book an initial interview');
      return (
        <InvestorForm setMode={setMode} agreementSchema={GET_AGREEMENT} closeModal={closeModal} />
      );

    case 'signUp_business':
      setTitle('Book an initial interview');
      return (
        <BusinessForm setMode={setMode} agreementSchema={GET_AGREEMENT} closeModal={closeModal} />
      );

    case 'signUp_expert':
      setTitle('Book an initial interview');
      return (
        <ExpertForm setMode={setMode} agreementSchema={GET_AGREEMENT} closeModal={closeModal} />
      );

    default:
      return (
        <SignInModal
          signInWithGoogle={signInWithGoogle}
          setMode={setMode}
          closeModal={closeModal}
          handler={loginHandler}
        />
      );
  }
};

export const SignInUpModalWrapper = ({
  open,
  openMode,
  closeModal,
  loginHandler,
  signUpHandler,
  signUpRefHandler,
  agreementSchema,
  signInWithGoogle,
}) => {
  const [mode, setMode] = useState('in');
  const [title, setTitle] = useState('Sign in');

  const closeHandler = () => {
    closeModal();
    setTimeout(() => setMode(openMode || 'in'), 400);
  };

  useEffect(() => {
    if (openMode) {
      setMode(openMode);
    }
  }, [openMode]);

  return (
    <Modal
      title={title}
      open={open}
      onClose={closeHandler}
      onClick={(e) => e.stopPropagation()}
      closeOnlyByControls
    >
      <SectionsSwitcher
        mode={mode}
        setMode={setMode}
        setTitle={setTitle}
        closeModal={closeHandler}
        loginHandler={loginHandler}
        signUpRefHandler={signUpRefHandler}
        signUpHandler={signUpHandler}
        agreementSchema={agreementSchema}
        signInWithGoogle={signInWithGoogle}
      />
    </Modal>
  );
};
