import React from 'react';
import MapComponent from './MapComponent';
import { Wrapper } from '@googlemaps/react-wrapper';

const DEFAULT_CENTER = { lat: 47.368702, lng: 8.537458 };

const Map = ({
  coordinates,
  getCoordinates,
  getAddress,
  address,
  getAddressData,
  coordinatesList,
  apiKey,
  isList,
  zoom,
  isEditable,
  showSearch,
  activeRole,
}) => (
  <>
    <Wrapper style={{ width: '100%', height: '100%' }} apiKey={apiKey}>
      <MapComponent
        coordinates={coordinates}
        getCoordinates={getCoordinates}
        getAddress={getAddress}
        address={address}
        getAddressData={getAddressData}
        coordinatesList={coordinatesList}
        isList={isList}
        zoom={zoom}
        isEditable={isEditable}
        showSearch={showSearch}
        activeRole={activeRole}
      />
    </Wrapper>
  </>
);

Map.defaultProps = {
  coordinates: DEFAULT_CENTER,
  getCoordinates: () => {},
  getAddress: () => {},
  address: '',
  apiKey: '',
  getAddressData: () => {},
  isEditable: true,
  showSearch: true,
  zoom: 10,
  coordinatesList: null,
  isList: false,
};

export default Map;
