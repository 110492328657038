import React from 'react';

const LoginIcon = ({ className, style }) => (
  <span className={className} style={style}>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.2806 12.5306L9.53063 16.2806C9.46094 16.3503 9.37822 16.4056 9.28717 16.4433C9.19613 16.481 9.09855 16.5004 9 16.5004C8.90145 16.5004 8.80387 16.481 8.71283 16.4433C8.62178 16.4056 8.53906 16.3503 8.46937 16.2806C8.39969 16.2109 8.34442 16.1282 8.3067 16.0372C8.26899 15.9461 8.24958 15.8485 8.24958 15.75C8.24958 15.6515 8.26899 15.5539 8.3067 15.4628C8.34442 15.3718 8.39969 15.2891 8.46937 15.2194L10.9397 12.75H2.25C2.05109 12.75 1.86032 12.671 1.71967 12.5303C1.57902 12.3897 1.5 12.1989 1.5 12C1.5 11.8011 1.57902 11.6103 1.71967 11.4697C1.86032 11.329 2.05109 11.25 2.25 11.25H10.9397L8.46937 8.78063C8.32864 8.63989 8.24958 8.44902 8.24958 8.25C8.24958 8.05098 8.32864 7.86011 8.46937 7.71937C8.61011 7.57864 8.80098 7.49958 9 7.49958C9.19902 7.49958 9.38989 7.57864 9.53063 7.71937L13.2806 11.4694C13.3504 11.539 13.4057 11.6217 13.4434 11.7128C13.4812 11.8038 13.5006 11.9014 13.5006 12C13.5006 12.0986 13.4812 12.1962 13.4434 12.2872C13.4057 12.3783 13.3504 12.461 13.2806 12.5306ZM18 3H12.75C12.5511 3 12.3603 3.07902 12.2197 3.21967C12.079 3.36032 12 3.55109 12 3.75C12 3.94891 12.079 4.13968 12.2197 4.28033C12.3603 4.42098 12.5511 4.5 12.75 4.5H18V19.5H12.75C12.5511 19.5 12.3603 19.579 12.2197 19.7197C12.079 19.8603 12 20.0511 12 20.25C12 20.4489 12.079 20.6397 12.2197 20.7803C12.3603 20.921 12.5511 21 12.75 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V4.5C19.5 4.10218 19.342 3.72064 19.0607 3.43934C18.7794 3.15804 18.3978 3 18 3Z"
        fill="#212121"
      />
    </svg>
  </span>
);

export default LoginIcon;
