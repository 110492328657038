/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */

import React, { useContext, useEffect, useState } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3';

import WalletContext from './WalletContext';
import { fromAscii, fromWei, isAddress, isNaN, sha3 } from '../../utils/web3_helper';
import {
  chainSettings,
  getAccessToken,
  showError,
  updateWalletStateTimeout,
} from '../../utils/common';
import { callContractMethod, post, sendContractMethod } from '../../utils/request';

import WalletStateUnknown from './assets/wallet-state-unknown';
import WalletStatePending from './assets/wallet-state-pending';
import WalletStateSuspend from './assets/wallet-state-suspend';
import WalletStateAccepted from './assets/wallet-state-accepted';
import WalletStateBlocked from './assets/wallet-state-blocked';

const { REACT_APP_SSO_API } = process.env;

const WalletProvider = (props) => {
  const { children, web3, setWeb3, userContextObject = null } = props;

  const { chainId, account, status, ethereum } = useMetaMask();

  const { user } = useContext(userContextObject);

  /** Статус регистрации кошелька @type {Number} */
  const [registerWalletState, setRegisterWalletState] = useState(-1);
  /** Иконка статуса регистрации кошелька */
  const [registerWalletStateIcon, setRegisterWalletStateIcon] = useState(<WalletStateUnknown />);
  /** Баланс на восстановление @type {?BigInt} */
  const [claimAmount, setClaimAmount] = useState(null);
  /** Баланс кошелька @type {?BigInt} */
  const [balance, setBalance] = useState(null);

  const registerWalletStateIcons = {
    0: <WalletStatePending />,
    1: <WalletStateSuspend />,
    2: <WalletStateAccepted />,
    3: <WalletStateBlocked />,
    4: <WalletStateBlocked />,
    5: <WalletStateBlocked />,
    6: <WalletStateUnknown />,
  };

  /**
   * Геттер иконки отображающей статут контракта регистрации кошелька
   * @param {Number} state
   * @return {string}
   */
  const getRegisterWalletStateIcon = (state = 6) => registerWalletStateIcons[state];

  const registerWalletStatusesStates = {
    0: 'pending',
    1: 'suspend',
    2: 'accepted',
    3: 'reject',
    4: 'blocked',
    5: 'deleted',
    6: 'unknown',
  };

  /**
   * Геттер текстового описания статуса кошелька
   * @param {Number} state
   * @return {string}
   */
  const getRegisterWalletStateText = (state) => registerWalletStatusesStates[state] || 'unknown'; // Unknown

  /**
   * Геттер статуса регистрации кошелька
   * @param {String} [acc] - адрес кошелька
   * @return {Promise<number>}
   */
  const getRegisterWalletState = async (acc) => {
    if (chainSettings?.chainId === chainId && ((acc && isAddress(acc)) || isAddress(account))) {
      console.log(
        `getRegisterWalletState: call contract`,
        'RegisterWalletFactory',
        'checkState',
        acc || account
      );
      const res = parseInt(
        await callContractMethod(
          web3,
          status,
          account,
          'RegisterWalletFactory',
          'checkState',
          [acc || account],
          true
        ).catch((err) => showError(err)),
        10
      );

      setRegisterWalletState(res);
      setRegisterWalletStateIcon(getRegisterWalletStateIcon(res));

      return res;
    }
  };

  const _wallets = {};

  /**
   * Добавление кошелька к учётке пользователя (нужно для генерации UID для получения ссылки на публичный сертификат)
   * @return {Promise<void>}
   */
  const addWalletToUser = async () => {
    const accessToken = getAccessToken();
    if (user?.id && accessToken) {
      if (!_wallets[user?.id]?.hasOwnProperty(account)) {
        try {
          const URL = `${REACT_APP_SSO_API}/wallet/add`;
          await post(
            URL,
            {
              authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            `wallet=${account}`,
            true
          ).catch((err) => showError(err));
          // eslint-disable-next-line no-empty
        } catch (err) {}
        if (!_wallets[user?.id]) {
          _wallets[user?.id] = {};
        }
        _wallets[user?.id][account] = true;
      }
    }
  };

  /**
   * Получение UID кошелька пользователя
   * @return {Promise<String>}
   */
  const getWalletUID = async () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      try {
        const URL = `${REACT_APP_SSO_API}/wallet/code`;
        const res = await post(
          URL,
          {
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          `wallet=${account}`,
          false
        ).catch((err) => showError(err));

        return res.id;
        // eslint-disable-next-line no-empty
      } catch (err) {
        console.error(err);
      }
    }
  };

  /**
   * Регистрация кошелька в сети
   * @return {Promise<void>}
   */
  const doRegisterWalletInChain = async () => {
    if (chainSettings?.chainId === chainId) {
      if (user?.id) {
        await sendContractMethod(
          web3,
          status,
          account,
          'RegisterWalletFactory',
          'addNewWallet',
          [
            fromAscii(user?.id?.replace(/-/gim, '')),
            sha3(`${user?.first_name || ''}|${user?.last_name || ''}|${user?.email || ''}`),
          ],
          false
        ).catch((err) => showError(err));

        return addWalletToUser();
      }
    }
  };

  /**
   * Получение баланса кошелька
   * @params {String} [wallet=account]
   * @return {Promise<String>}
   */
  const getBalance = async (wallet) => {
    if (web3 && status === 'connected' && isAddress(wallet || account)) {
      const _balance = await web3.eth.getBalance(wallet || account, 'latest');
      if (!isNaN(_balance)) {
        const bal = fromWei(_balance) || '';
        if (!wallet) {
          setBalance(bal);
        }
        return bal; // Баланс в нормальных единицах
      }
      return '';
    }
    return '';
  };

  /**
   * Получение баланса восстановления
   * @return {Promise<Number>}
   */
  const getClaimAmount = async () => {
    if (chainSettings?.chainId === chainId) {
      const _claimAmount = parseInt(
        await callContractMethod(
          web3,
          status,
          account,
          'Claim',
          'getSelfBalance',
          null,
          true
        ).catch((err) => showError(err)),
        10
      );
      setClaimAmount(_claimAmount || 0);
      return _claimAmount;
    }
  };

  /**
   * Восстановление баланса кошелька в сети
   * @return {Promise<void>}
   */
  const doClaim = async () => {
    if (chainSettings?.chainId === chainId) {
      if (user?.id) {
        return sendContractMethod(web3, status, account, 'Claim', 'restoreBalance', []).catch(
          (err) => showError(err)
        );
      }
    }
  };

  //--------------------------

  /**
   * Блокировка кошелька
   * @param {String} wallet
   * @param {String} [reason]
   * @return {Promise<void>}
   */
  const doWalletBlock = async (wallet, reason) => {
    if (chainSettings?.chainId === chainId) {
      if (isAddress(wallet)) {
        return sendContractMethod(web3, status, account, 'RegisterWalletFactory', 'blockWallet', [
          wallet,
          reason || '',
        ]).catch((err) => showError(err));
      }
    }
  };

  /**
   * Разблокировка кошелька
   * @param {String} wallet
   * @return {Promise<void>}
   */
  const doWalletUnblock = async (wallet) => {
    if (chainSettings?.chainId === chainId) {
      if (isAddress(wallet)) {
        return sendContractMethod(web3, status, account, 'RegisterWalletFactory', 'acceptWallet', [
          wallet,
        ]).catch((err) => showError(err));
      }
    }
  };

  //--------------------------

  useEffect(() => {
    console.log(`receive change account or net. Clear wallet state and balance`);
    setBalance('');
    setRegisterWalletState(-1);
    setRegisterWalletStateIcon(getRegisterWalletStateIcon(6));
  }, [status, chainId, account]);

  let chainUpdateHandler;
  const updateChainData = async () => {
    console.log(`update wallet state for ${account}(${user?.id}) in ${chainId}`);
    const arr = [];

    if (account) {
      if (chainId === chainSettings.chainId) {
        console.log(
          `getRegisterWalletState for ${account} in ${chainId}`,
          account,
          chainId,
          chainSettings.chainId
        );
        arr.push(getRegisterWalletState());
      }

      arr.push(getBalance());
      if (chainId === chainSettings.chainId) {
        console.log(
          `getBalance for ${account} in ${chainId}`,
          account,
          chainId,
          chainSettings.chainId
        );
        arr.push(getClaimAmount());
      }
    }

    if (user?.id && chainId === chainSettings.chainId) {
      if (registerWalletState === 2) {
        console.log(
          `addWalletToUser for ${account} in ${chainId}`,
          account,
          chainId,
          chainSettings.chainId,
          registerWalletState
        );
        addWalletToUser(user?.id).then();
      }
    }

    return Promise.all(arr).then(() => {
      window.clearInterval(chainUpdateHandler);
      chainUpdateHandler = setTimeout(updateChainData, updateWalletStateTimeout);
    });
  };

  /**
   * Запуск периодических проверок статуса кошелька (каждые 10 секунд)
   */
  useEffect(() => {
    console.log(`start periodical checked wallet state`);
    setBalance('');
    window.clearInterval(chainUpdateHandler);
    chainUpdateHandler = setTimeout(updateChainData, updateWalletStateTimeout);
    return () => clearInterval(chainUpdateHandler);
  }, [status, chainId, account]);

  //--------------------------

  useEffect(() => {
    console.info(`WalletProvider: changed WEB3: ${!!web3}`);
    if (!web3) {
      setWeb3(new Web3(ethereum || window.ethereum));
    }
  }, [web3]);

  //--------------------------

  const defaultValue = {
    registerWalletState,
    getRegisterWalletState,
    registerWalletStateIcon,
    getRegisterWalletStateIcon,
    getRegisterWalletStateText,
    claimAmount,
    getClaimAmount,
    balance,
    doRegisterWalletInChain,
    doClaim,
    getWalletUID,
    getBalance,
    doWalletBlock,
    doWalletUnblock,
  };

  return <WalletContext.Provider value={defaultValue}>{children}</WalletContext.Provider>;
};

export function useWallet() {
  const context = React.useContext(WalletContext);

  if (!context) {
    throw new Error('`useWallet` should be used within a `WalletProvider`');
  }

  return context;
}

export default WalletProvider;
