import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import ModalContainer from '../../ModalContainer';
import { AppContext } from '../../../context';
import { getBrowser } from '../../../utils/defineBrowser';
import useIsTouchDevice from '../../../utils/useIsTouchDevice';
import styles from './ErrorModal.module.scss';

const ErrorModal = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  title,
  subTitle,
  isOrientationModal,
  isKeyboardOpen,
}) => {
  const [browser, setBrowser] = useState();
  const { setIsError } = useContext(AppContext);

  const isTouchDevice = useIsTouchDevice();

  useEffect(() => {
    setBrowser(getBrowser());
  }, []);

  return (
    <>
      {isModelOpen ? (
        <div
          className={clsx(
            styles.errorModalWrapper,
            isOrientationModal ? styles.orientationModalWrapper : '',
            isKeyboardOpen && !browser.isSafari ? styles.keyboardVisible : '',
            !isTouchDevice ? styles.desktop : ''
          )}
        >
          <ModalContainer
            background={
              isOrientationModal ? '#212121' : 'no-repeat center/100% url(/images/error-bg.png)'
            }
            handleClose={() => {
              handleClose(setIsOpen);
              if (!isOrientationModal) {
                setIsError(false);
              }
            }}
            isErrorModal
            isOrientationModal
          >
            {title && <div className={styles.title}>{title}</div>}
            {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
            {handleClose && (
              <button
                className={styles.btn}
                type="button"
                onClick={() => {
                  handleClose(setIsOpen);
                  if (!isOrientationModal) {
                    setIsError(false);
                  }
                }}
              >
                OK
              </button>
            )}
          </ModalContainer>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ErrorModal;
