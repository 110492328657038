import React from 'react';
import styles from './Search.module.scss';
import { SearchIcon } from 'tt-ui-kit';

// import { ReactComponent as SearchIcon } from '../../assets/svg/navbar/search-icon.svg';

const SearchNav = () => (
  <div className={styles.searchBox}>
    <div className={styles.searchImg}>
      <SearchIcon style={{ width: 18, height: 18, opacity: 0.8 }} />
    </div>
    <div className={styles.search}>
      <input placeholder="Search..." className={styles.searchInput} />
    </div>
  </div>
);

export default SearchNav;
