import Web3 from 'web3';

export * as web3Validator from 'web3-validator';

export function toBN(val) {
  try {
    return BigInt(val);
  } catch (err) {
    // console.warn(`"${val} is not a BigInt"`);
    return NaN;
  }
}

export function isBN(val) {
  return typeof val === 'bigint';
}

export function isNaN(val) {
  return Number.isNaN(val);
}

export function isAddress(val) {
  return Web3.utils.isAddress(val);
}

export function toWei(val) {
  return toBN(Web3.utils.toWei(val, 'ether'));
}

export function fromWei(val) {
  return Web3.utils.fromWei(toBN(val), 'ether');
}

export function hexToNumber(val) {
  return Web3.utils.hexToNumber(val);
}

export function fromAscii(val) {
  return Web3.utils.asciiToHex(val);
}

export function toAscii(val) {
  return Web3.utils.hexToAscii(val);
}

export function sha3(val) {
  return Web3.utils.sha3(val);
}

export function toJSON(obj) {
  try {
    return JSON.stringify(
      obj,
      (key, value) => (typeof value === 'bigint' ? `${value.toString()}n` : value),
      ''
    );
  } catch (err) {
    return '';
  }
}

export function fromJSON(json) {
  try {
    return JSON.parse(json, (key, value) =>
      typeof value === 'string' && /^\d+n$/.test(value) ? BigInt(/^(\d+)/.exec(value)[1]) : value
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * @param {String} str
 * @return {Number|BigInt}
 */
export function hex2num(str) {
  return Web3.utils.hexToNumber(str);
}

/**
 * @param {Number} num
 * @return {String}
 */
export function num2hex(num) {
  return Web3.utils.numberToHex(num);
}

/**
 * @param {String} str
 * @return {String}
 */
export function hex2str(str) {
  return Web3.utils.hexToString(str);
}

/**
 * @param {String} str
 * @return {String}
 */
export function str2hex(str) {
  return Web3.utils.stringToHex(str);
}

/**
 * @param {Number} val
 * @return {Date}
 */
export function int2dateTime(val) {
  return new Date(parseInt(val, 10) * 1000);
}

/**
 * @param {Date} date
 * @return {Number}
 */
export function dateTime2int(date) {
  // eslint-disable-next-line no-param-reassign
  date = typeof date === 'string' ? new Date(date) : date;
  // eslint-disable-next-line no-unsafe-optional-chaining
  return date?.getTime ? date?.getTime() / 1000 : null;
}

/**
 * @param {Date} date
 * @return {String}
 */
export function dateTime2dateStr(date) {
  return date?.toLocaleDateString() ?? '';
}

/**
 * @param {Date} date
 * @return {String}
 */
export function dateTime2timeStr(date) {
  return date?.toLocaleTimeString() ?? '';
}

/**
 * @param {Date} date
 * @return {String}
 */
export function dateTime2dateTimeStr(date) {
  return date?.toLocaleString() ?? '';
}

/**
 *
 * @param {Object} abi
 * @returns {Boolean}
 */
export function isABI(abi) {
  return abi && typeof abi.find === 'function';
}
