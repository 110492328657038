import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { Button, TextField } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import { FORGOT_USER_PASSWORD } from '../../../constants/api';
import styles from '../SignInModal.module.scss';

const schema = yup.object({
  email: yup
    .string()
    .required('Please enter your email address')
    .email('Please enter a valid email address, e.g. joe@mail.com'),
});

export const ResetModal = ({ setMode }) => {
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const [resetPassword] = useMutation(FORGOT_USER_PASSWORD);

  const onChangeInput = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    resetPassword({ variables: { input: data } })
      .then(() => {
        openNotification({
          message: "We've sent you an email with a link to reset your password.",
          type: 'success',
        });
        setMode('in');
        setErrorEmail(false);
      })
      .catch(() => {
        setError('email', 'There is no user with this e-mail in our community');
        setErrorEmail(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.formWrapper}>
      <form name="login" method="POST" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputWrapper}>
          <TextField
            className={styles.formInput}
            label="Email *"
            disableUnderline
            onChange={(e) => onChangeInput('email', e.target.value)}
            error={!!errors.email}
            helperText={
              errorEmail ? (
                <span className={styles.formError}>
                  {' '}
                  • We can&apos;t find a user with that email address.
                </span>
              ) : null
            }
          />
        </div>

        <div className={styles.formBottom}>
          <div className={styles.formLink} onClick={() => setMode('in')}>
            Remember password? Sign in
          </div>
          <Button className={styles.formSubmitButton} type="primary" loading={loading} isSubmit>
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};
