import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import styles from './NavbarLink.module.scss';

const NavbarLink = ({ href, Icon, text, onClick }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return onClick ? (
    <span
      onClick={onClick}
      className={clsx(styles.navbarLink, activeLink === href ? styles.navbarLinkActive : '')}
    >
      <Icon className={clsx(styles.linkIcon, activeLink === href ? styles.linkIconActive : '')} />
      <span className={styles.navbarLinktext}>{text}</span>
    </span>
  ) : (
    <Link
      to={!href ? '/menu' : href}
      className={clsx(styles.navbarLink, activeLink === href ? styles.navbarLinkActive : '')}
      underline="none"
    >
      <Icon className={clsx(styles.linkIcon, activeLink === href ? styles.linkIconActive : '')} />
      <span className={styles.navbarLinktext}>{text}</span>
    </Link>
  );
};

export default NavbarLink;
