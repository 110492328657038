/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3';

import BridgeContext from './BridgeContext';
import {
  APIRequest,
  callContractMethodWithAddressABI,
  sendContractMethod,
  sendContractMethodWithAddressABI,
} from '../../utils/request';

import { fromWei } from '../../utils/web3_helper';
import { chainSettings, showError, updateWalletStateTimeout } from '../../utils/common';

const BridgeProvider = (props) => {
  const { children, web3, setWeb3 } = props;

  const { chainId, account, status, ethereum } = useMetaMask();

  //--------------------------

  /**
   * @typedef Bridges
   * @property {Number}  id
   * @property {String}  name
   * @property {Number}  chain_id
   * @property {String}  symbol
   * @property {Number}  digits
   * @property {Boolean} ssl
   * @property {String}  host
   * @property {?Number} port
   * @property {?String} path
   */

  /**
   * @typedef Bridge
   * @property {Number}  id
   * @property {String}  name
   * @property {Number}  chain_id
   * @property {Boolean} ssl
   * @property {String}  host
   * @property {?Number} port
   * @property {?String} path
   * @property {String}  symbol
   * @property {Number}  digits
   * @property {?String} bridge_addr
   * @property {?Object} bridge_abi
   * @property {?String} wrap_addr
   * @property {?Object} wrap_abi
   */

  /**
   * @typedef BridgeOperations
   * @property {String}  wallet
   * @property {String}  hash_from
   * @property {Number}  block_from
   * @property {String}  addr_from
   * @property {String}  date_from
   * @property {Number}  net_from
   * @property {Number}  nonce
   * @property {BigInt}  amount_from
   * @property {BigInt}  amount_from_full
   * @property {BigInt}  fee_from
   * @property {Number}  status_from
   * @property {?String} hash_to
   * @property {?Number} block_to
   * @property {String}  addr_to
   * @property {?String} date_to
   * @property {Number}  net_to
   * @property {?BigInt} amount_to
   * @property {?BigInt} amount_to_full
   * @property {?BigInt} fee_to
   * @property {Number}  status_to
   * @property {String}  hash_to_sign
   * @property {String}  signature
   * @property {?Object} error
   */

  /**
   * @typedef BridgeOperation
   * @property {String}  wallet
   * @property {String}  hash_from
   * @property {Number}  block_from
   * @property {String}  addr_from
   * @property {String}  date_from
   * @property {Number}  net_from
   * @property {Number}  nonce
   * @property {BigInt}  amount_from
   * @property {BigInt}  amount_from_full
   * @property {BigInt}  fee_from
   * @property {Number}  status_from
   * @property {?String} hash_to
   * @property {?Number} block_to
   * @property {String}  addr_to
   * @property {?String} date_to
   * @property {Number}  net_to
   * @property {?BigInt} amount_to
   * @property {?BigInt} amount_to_full
   * @property {?BigInt} fee_to
   * @property {Number}  status_to
   * @property {String}  hash_to_sign
   * @property {String}  signature
   * @property {?Object} error
   * @property {String}  net_from_name
   * @property {String}  net_to_name
   * @property {String}  net_from_symbol
   * @property {String}  net_to_symbol
   */

  /**
   * @typedef BridgeSwap
   * @property {Number}  chainIdTo
   * @property {String}  tokenFrom
   * @property {String}  tokenTo
   * @property {BigInt}  amount
   * @property {BigInt}  amount_full
   */

  /**
   * @typedef BridgeRedeem
   * @property {Number}                tokenFrom,
   * @property {String|Number|BigInt}  amount
   * @property {Number}                chainIdFrom
   * @property {String}                tokenFrom
   * @property {String}                tokenTo
   * @property {Number}                nonce
   * @property {String}                signature
   */

  /** Массив доступный мостов @type{[Bridges]} */
  const [bridges, setBridges] = useState(/** @type {[Bridges]} */ []);
  /** Настройки текущего выбранного моста @type{Bridge} */
  const [bridge, setBridge] = useState(/** @type {Bridge} */ {});

  /** Баланс кошелька в сети моста @type{?BigInt} */
  const [wrapBalance, setWrapBalance] = useState(null);
  const _bridgesInfo = {};

  /**
   * Получение списка активных мостов
   * @params {Boolean} [force]
   * @return {Promise<Bridges[]>}
   */
  const getBridges = async (force) => {
    if (bridges?.length === 0 || force) {
      /** @type {{result: Bridges[]}} */
      const data = await APIRequest('getBridges', {}, true);
      const brdgs = data || [];
      setBridges(brdgs);

      // Тут же пробуем получить активный мост
      const chId = parseInt(chainId, 16);
      const brdg = brdgs.find((el) => el?.chain_id === chId);
      setBridge((brd) => {
        console.info('getBridges: Set new current bridge.', brd, brdg);
        return brdg;
      });

      return data || [];
    }
    return bridges;
  };

  /**
   * Получение детализации по конкретному мосту
   * @params {Number}   id
   * @params {Boolean} [force]
   * @return {Promise<Bridge>}
   */
  const getBridge = async (id, force) => {
    let res = _bridgesInfo[id];
    if ((!res || force) && id) {
      [res] = await APIRequest('getBridgeInfo', { bridgeID: id }, false);
      _bridgesInfo[id] = res;
      return res;
    }
    return res;
  };

  /**
   * Получение массива операций по мосту
   * @params {Object|{onlyPending: Boolean, operationDateStart?: Date|String, operationDateEnd?: Date|String}} filter
   * @return {Promise<[BridgeOperations]>}
   */
  const getBridgeOperations = async (filter) => {
    /** @type {{result: [BridgeOperations]}} */
    const data = await APIRequest('getBridgeOperations', {
      wallet: account,
      net: parseInt(chainId, 16),
      onlyPending: filter?.onlyPending,
      operationDateStart: filter?.operationDateStart,
      operationDateEnd: filter?.operationDateEnd,
    });
    return data || [];
  };

  /**
   * Получение массива операций по мосту
   * @params {Object|{onlyPending: Boolean}} filter
   * @return {Promise<[BridgeOperations]>}
   */
  const getBridgeOperationsToReceive = async () => {
    /** @type {Object|Array} */
    const data = await APIRequest('getBridgeOperationsToReceive', {
      wallet: account,
      net: parseInt(chainId, 16),
    });
    return data || [];
  };

  /**
   * Получение детализации по операции по мосту
   * @params {Object|?{wallet: String, hash: String, net: Number, nonce: Number}} filter
   * @return {Promise<BridgeOperation>}
   */
  const getBridgeOperation = async (filter) => {
    /** @type {{result: [Bridge]}} */
    const data = await APIRequest('getBridgeOperation', {
      wallet: account,
      net_from: filter?.netFrom,
      net_to: filter?.netTo,
      addr_from: filter?.addrFrom,
      addr_to: filter?.addrTo,
      nonce: filter?.nonce,
    });
    return data[0] || {};
  };

  /**
   * Получение детализации по операции по мосту
   * @params {Object|?{wallet: String, hash: String, net: Number, nonce: Number}} filter
   * @return {Promise<BridgeOperation>}
   */
  const findBridgeOperation = async (filter) => {
    /** @type {{result: [Bridge]}} */
    const data = await APIRequest('findBridgeOperation', {
      wallet: account,
      signature: filter?.signature,
    });
    return data[0] || {};
  };

  /**
   * Отправка в мост
   * @params {Object|BridgeSwap} filter
   * @return {Promise<void>}
   */
  const bridgeSwap = async (filter) => {
    console.log('bridgeSwap:');
    console.dir(filter);
    if (chainId === chainSettings?.chainId) {
      return sendContractMethod(
        web3,
        status,
        account,
        'EthereumBridge',
        'swap',
        [filter?.chainIdTo, filter?.tokenFrom, filter?.tokenTo, filter?.amount],
        false,
        filter?.amount_full,
        350000n // TODO: Fix for DEV NET. Transaction getting wrong gas (lower then need)
      ).catch((err) => showError(err));
    }

    return sendContractMethodWithAddressABI(
      web3,
      status,
      account,
      bridge?.bridge_addr,
      bridge?.bridge_abi,
      'swap',
      [filter?.chainIdTo, filter?.tokenFrom, filter?.tokenTo, filter?.amount],
      false
      // filter?.amount_full,
    ).catch((err) => showError(err));
  };

  /**
   * Получение из моста
   * @params {Object|BridgeRedeem} filter
   * @return {Promise<void>}
   */
  const bridgeRedeem = async (filter) => {
    const params = [
      account,
      filter?.amount,
      filter?.chainIdFrom,
      filter?.tokenFrom,
      filter?.tokenTo,
      filter?.nonce,
      web3.utils.bytesToHex(
        web3.utils.hexToBytes(
          filter?.signature.substring(1, 2) === '0x' ? filter?.signature : `0x${filter?.signature}`
        )
      ),
    ];
    console.log(66, params);

    if (chainId === chainSettings?.chainId) {
      return sendContractMethod(web3, status, account, 'EthereumBridge', 'redeem', params).catch(
        (err) => showError(err)
      );
    }

    return sendContractMethodWithAddressABI(
      web3,
      status,
      account,
      bridge?.bridge_addr,
      bridge?.bridge_abi,
      'redeem',
      params,
      false
    ).catch((err) => showError(err));
  };

  /**
   * Получение баланса из моста
   * @return {Promise<void>}
   */
  const getWrapBalance = async () => {
    if (
      status === 'connected' &&
      account &&
      bridge &&
      bridge.wrap_addr &&
      bridge.wrap_abi &&
      parseInt(chainId, 16) === bridge.chain_id
    ) {
      const res = await callContractMethodWithAddressABI(
        web3,
        status,
        account,
        bridge.wrap_addr,
        bridge.wrap_abi,
        'balanceOf',
        [account],
        true
      ).catch((err) => showError(err));

      if (res) {
        const _balance = fromWei(res || '', 'ether');
        setWrapBalance((_res) => _balance);
      } else {
        setWrapBalance((_res) => 0);
      }
    }
  };

  //--------------------------

  let chainUpdateHandler;

  const updateChainData = async () => {
    if (chainId !== chainSettings.chainId) {
      return getWrapBalance();
    }
  };

  // Первый запрос, что бы прошёл сразу
  useEffect(() => {
    chainUpdateHandler = setTimeout(updateChainData, 100);
    return () => clearInterval(chainUpdateHandler);
  }, []);

  /**
   * Запуск периодических проверок статуса кошелька (каждые 10 секунд)
   */
  useEffect(() => {
    setWrapBalance('');
    window.clearInterval(chainUpdateHandler);
    chainUpdateHandler = setTimeout(updateChainData, updateWalletStateTimeout);
    return () => clearInterval(chainUpdateHandler);
  }, [status, chainId, account]);

  /**
   * Запрос мостов (однократно при запуске)
   */
  useEffect(() => {
    (async () => {
      const currChainId = parseInt(chainId, 16);
      getBridges(true).then((brdgs) => {
        const brdg = (brdgs || []).find((el) => el.chain_id === currChainId);
        setBridge((brd) => {
          console.info('UseEffect - getBridges.then: Set new current bridge.', brd, brdg);
          return brdg;
        });
      });
    })();
  }, []);

  /**
   * Установка настроек текущего выбранного моста по выбранной сети
   * (при каждом изменении списка мостов и смене сети)
   */
  useEffect(() => {
    (async () => {
      const chId = parseInt(chainId, 16);
      /** @type {Number} */
      const brdgId = (bridges || []).find(/** @type {Bridge} */ (el) => el?.chain_id === chId)?.id;
      const brdg = await getBridge(brdgId, false);
      console.log(`change net and change current bridge:`, brdg);
      setBridge((brd) => {
        console.info('UseEffect(2). Set new current bridge.', brd, brdg);
        return brdg;
      });
    })();
  }, [bridges, chainId]);

  //--------------------------

  useEffect(() => {
    console.info(`BridgeProvider: changed WEB3: ${!!web3}`);
    if (!web3) {
      setWeb3(new Web3(ethereum || window.ethereum));
    }
  }, [web3]);

  //--------------------------

  const defaultValue = {
    bridges,
    bridge,
    wrapBalance,
    getWrapBalance,
    getBridges,
    getBridge,
    getBridgeOperations,
    getBridgeOperationsToReceive,
    getBridgeOperation,
    findBridgeOperation,
    bridgeSwap,
    bridgeRedeem,
  };

  return <BridgeContext.Provider value={defaultValue}>{children}</BridgeContext.Provider>;
};

export function useBridge() {
  const context = React.useContext(BridgeContext);

  if (!context) {
    throw new Error('`useBlockChainAPI` should be used within a `BlockChainAPIProvider`');
  }

  return context;
}

export default BridgeProvider;
