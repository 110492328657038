import React from 'react';
import { AppState } from './context';
import './index.scss';
import './styles/globals.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './utils/api-client';
import { HelmetProvider } from 'react-helmet-async';
import UserState from './context/User/UserState';
import MessengerState from './context/Messenger/MessengerState';

import { createTheme, ThemeProvider } from '@mui/material'; // TODO: remove
import ScrollToTop from './hoc/ScrollToTop';
import { DashboardRoutes } from './views/DashboardRoutes';
import GoogleMetrics from './utils/googleMetrics';

import DisconnectHandlerWrapper from './utils/disconnectHandler';
import { MetaMaskProvider } from 'metamask-react';

import 'tt-ui-kit/dist/index.css'; // TODO: remove
import { ConfigProvider } from 'tt-ui-lib/core';
import 'tt-ui-lib/core/style.css';
import NotificationState from './context/Notification/NotificationState';
import AdminRoutes from 'views/Admin';

const ROUTES_INFO = [
  {
    path: '/',
    element: <Navigate to="/dashboard/api" />,
  },
  {
    path: '/dashboard/*',
    element: <DashboardRoutes />,
  },
  {
    path: '/admin/*',
    element: <AdminRoutes />,
  },
];

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
    white: {
      main: '#ffffff',
      contrastText: '#6a6c60',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const helmetContext = {};

const App = () => (
  <BrowserRouter>
    <HelmetProvider context={helmetContext}>
      <ApolloProvider client={client}>
        <AppState>
          <UserState>
            <MessengerState>
              <NotificationState>
                <ThemeProvider theme={theme}>
                  <ConfigProvider>
                    <DisconnectHandlerWrapper />
                    <GoogleMetrics />
                    <ScrollToTop />
                    <MetaMaskProvider>
                      <Routes>
                        {ROUTES_INFO.map((routeInfo) => (
                          <Route
                            path={routeInfo.path}
                            element={routeInfo.element}
                            key={routeInfo.path}
                          />
                        ))}
                      </Routes>
                    </MetaMaskProvider>
                  </ConfigProvider>
                </ThemeProvider>
              </NotificationState>
            </MessengerState>
          </UserState>
        </AppState>
      </ApolloProvider>
    </HelmetProvider>
  </BrowserRouter>
);

export default App;
