import { API_BASE_API_SSO_URL } from '../urls';

export const API_CHAT_GET_CHATS = `${API_BASE_API_SSO_URL}/chats/chats`;
export const API_CHAT_USERS_LIST = `${API_BASE_API_SSO_URL}/users_details`;
export const API_CHAT_DIRECT_MESSAGES = `${API_BASE_API_SSO_URL}/chats/directMessage`;
export const API_CHAT_MESSAGES = `${API_BASE_API_SSO_URL}/chats/chatMessages`;
export const API_CHAT_START_CALL = `${API_BASE_API_SSO_URL}/chats/directCall`;
export const API_CHAT_CHANGE_CALL = `${API_BASE_API_SSO_URL}/chats/directCallStatus`;
export const API_CHAT_END_DIRECT_CALL = `${API_BASE_API_SSO_URL}/chats/directCallEnd`;
export const API_CHAT_GET_FOLDERS = `${API_BASE_API_SSO_URL}/chats/chatCategories`;
export const API_CHAT_CREATE_CATEGORY = `${API_BASE_API_SSO_URL}/chats/createChatCategory`;
export const API_CHAT_APPEND_CATEGORY = `${API_BASE_API_SSO_URL}/chats/appendChatCategoryChat`;
export const API_CHAT_DELETE_FOLDER = `${API_BASE_API_SSO_URL}/chats/destroyChatCategory`;
