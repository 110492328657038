import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './AdminNavbarLink.module.scss';

const NavbarLink = ({ href, text, className }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <div className={styles.navbarLinkRow}>
      <Link
        // TODO we need to decide where we end up after clicking
        to={!href ? '/dashboard/feed' : href}
        className={`${
          activeLink === href ? styles.navbarLinkActive : styles.navbarLink
        } ${className}`}
        underline="none"
      >
        <span className={styles.navbarLinktext}>{text}</span>
      </Link>
    </div>
  );
};

export default NavbarLink;
