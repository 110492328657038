import { openNotification } from 'tt-ui-lib/core';
import Cookies from 'js-cookie';

const {
  REACT_APP_CHAIN_ID,
  REACT_APP_CHAIN_NAME,
  REACT_APP_CHAIN_TOKEN,
  REACT_APP_CHAIN_RPC_URL,
  REACT_APP_CHAIN_BLOCKSCAN_URL,
} = process.env;

export const chainSettings = {
  chainId: REACT_APP_CHAIN_ID,
  chainName: REACT_APP_CHAIN_NAME,
  rpcUrls: [REACT_APP_CHAIN_RPC_URL],
  nativeCurrency: {
    name: REACT_APP_CHAIN_TOKEN,
    symbol: REACT_APP_CHAIN_TOKEN,
    decimals: 18,
  },
  blockExplorerUrls: REACT_APP_CHAIN_BLOCKSCAN_URL ? [REACT_APP_CHAIN_BLOCKSCAN_URL] : null,
  iconUrls: [`${REACT_APP_CHAIN_RPC_URL}/img/logo.svg`],
};

//-----------------------------

export const updateWalletStateTimeout = 5000;

let standByCounter = 0;
export const showStandby = () => {
  try {
    standByCounter++;

    const metamaskComponentLoader = document.getElementById('MetamaskComponentLoader');
    const loader = document.getElementById('DALoader');

    if (metamaskComponentLoader) {
      metamaskComponentLoader.style.opacity = '1';
      metamaskComponentLoader.style.visibility = '';
      metamaskComponentLoader.style.display = '';
    } else if (loader) {
      loader.style.opacity = '1';
      loader.style.visibility = '';
      loader.style.display = 'flex';
    }
  } catch (err) {
    // hide error
  }
};

export const hideStandby = (force) => {
  try {
    standByCounter--;
    if (force) {
      standByCounter = 0;
    }

    const metamaskComponentLoader = document.getElementById('MetamaskComponentLoader');
    const loader = document.getElementById('DALoader');

    if (metamaskComponentLoader && !standByCounter) {
      metamaskComponentLoader.style.opacity = '0';
      metamaskComponentLoader.style.visibility = 'none';
      metamaskComponentLoader.style.display = 'none';
    } else if (loader && !standByCounter) {
      loader.style.opacity = '0';
      loader.style.visibility = 'none';
      loader.style.display = 'none';
    }
  } catch (err) {
    // hide error
  }
};

//-------------------

export const showError = (msg) =>
  openNotification({
    message: msg?.message || msg,
    type: 'error',
  });

export const showWarn = (msg) =>
  openNotification({
    message: msg,
    type: 'warning',
  });

export const showInfo = (msg) =>
  openNotification({
    message: msg,
    type: 'info',
  });

export const showSuccess = (msg) =>
  openNotification({
    message: msg,
    type: 'success',
  });

export const getAccessToken = () => Cookies.get('access_token') || '';

//-------------------------------------------

/**
 * Сериализация в JSON
 * @param {*}        obj
 * @param {Boolean} [pretty]
 * @return {string}
 */
export function obj2json(obj, pretty) {
  return obj
    ? typeof obj === 'object'
      ? JSON.stringify(
          obj,
          obj instanceof Error
            ? Object.getOwnPropertyNames(obj)
            : (_, v) => (typeof v === 'bigint' ? v.toString() : v),
          pretty ? 2 : null
        )
      : `{"value": ${typeof obj === 'string' ? `"${obj}"` : String(obj)}}`
    : obj;
}

/**
 * Сериализация в Object из JSON
 * @param {String} str
 * @return {Object}
 */
export function json2obj(str) {
  return JSON.parse(str ?? '{}', (key, val) => {
    const v = parseFloat(val);
    return Number.isNaN(v) ? val : v.toString() === val ? v : val;
  });
}

export const openAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = 'hidden';
  }
};
