import { Modal } from 'tt-ui-lib/core';
import { Button, TextInput } from 'tt-ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import styles from './BookCallModal.module.scss';
import * as yup from 'yup';

const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'"/;`%]*$/;
const numberReg = /^([^0-9]*)$/;

const schema = yup.object({
  first_name: yup
    .string()
    .required('The first name field must not be empty.')
    .max(255, 'Too many characters.')
    .matches(whitespacesReg, 'The field cannot contain only spaces.')
    .matches(specialCharReg, 'Special characters are prohibited.')
    .matches(numberReg, 'Numbers are prohibited.'),
  last_name: yup
    .string()
    .max(255, 'Too many characters.')
    .matches(whitespacesReg, 'The field cannot contain only spaces.')
    .matches(specialCharReg, 'Special characters are prohibited.')
    .matches(numberReg, 'Numbers are prohibited.'),
  email: yup
    .string()
    .required('Please enter your email address.')
    .email('Please enter a valid email address, e.g. joe@mail.com.'),
  company: yup.string().required('Please enter your company.').max(255, 'Too many characters.'),
});

const BookCallModal = ({ isModelOpen, setIsModalOpen }) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      comment: '',
    },
  });

  const onChangeInput = (name, value) => {
    setValue(name, value);
  };

  return (
    <Modal
      open={isModelOpen}
      onClose={() => setIsModalOpen(!isModelOpen)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width={400}
    >
      <div className={styles.bookCallTitleBlock}>
        <p className={styles.bookCallTitle}>Coming soon!</p>
      </div>
      {/* <form>
        <div className={styles.modal_content}>
          <div className={styles.modal_content_first}>
            <div className={styles.row}>
              <div className={styles.modal_input}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="First Name"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('first_name', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.first_name?.message && (
                  <p className={styles.errorMessage}>{errors.first_name?.message}</p>
                )}
              </div>
              <div className={styles.modal_input}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="Last Name"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('last_name', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.last_name?.message && (
                  <p className={styles.errorMessage}>{errors.last_name?.message}</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.modal_input} style={{ width: '100%' }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="Email"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('email', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.email?.message && (
                  <p className={styles.errorMessage}>{errors.email?.message}</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.modal_input} style={{ width: '100%' }}>
                <Controller
                  name="company"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="Company"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('company', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.email?.message && (
                  <p className={styles.errorMessage}>{errors.email?.message}</p>
                )}
              </div>
            </div>
          </div>
          <div>
            <Controller
              name="comment"
              control={control}
              render={({ field: { value } }) => (
                <textarea
                  style={{ padding: '10px' }}
                  className={styles.modal_textArea}
                  inputMode="text"
                  placeholder="Please elaborate on your request to help us better prepare for the call."
                  value={value}
                  onChange={(e) => onChangeInput('comment', e.target.value)}
                />
              )}
            />
          </div>
          <div className={styles.modal_button}>
            <div style={{ display: 'flex', justifyItems: 'flex-start' }}>
              <Button type="default" onClick={() => setIsModalOpen(!isModelOpen)}>
                CANCEL
              </Button>
            </div>
            <div style={{ display: 'flex', justifyItems: 'flex-end' }}>
              <Button className={styles.modal_buttonSend} type="primary" isSubmit>
                BOOK
              </Button>
            </div>
          </div>
        </div>
      </form> */}
    </Modal>
  );
};

export default BookCallModal;
