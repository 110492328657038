import React, { useEffect } from 'react';
import { DisconnectHandler } from '../modules/tt-disconnect';
import { useLocation } from 'react-router';
import { GET_CURRENT_USER, LOGOUT_USER } from '../api';
import { useLazyQuery, useMutation } from '@apollo/client';
import { logoutUserHandler } from './appUtils';
import { useSelectedContext } from '../context/contextSelector';
import Cookies from 'js-cookie';

const DisconnectHandlerWrapper = () => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const location = useLocation();
  const { user, changeUserData } = useSelectedContext((context) => context.user);
  const { disconnectSocket } = useSelectedContext((context) => context.messenger);
  const token = Cookies.get('access_token');
  const cookieUserId = Cookies.get('userId');

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER);
  const [logoutUser] = useMutation(LOGOUT_USER);

  const logOut = () => {
    logoutUserHandler(logoutUser, disconnectSocket);
  };

  const getUser = async () => {
    const userValue = await getCurrentUser();
    if (userValue?.data?.currentUser) {
      changeUserData(userValue.data.currentUser);
    }
  };

  useEffect(() => {
    if (!user?.id) {
      getUser().then();
    }
  }, []);

  useEffect(() => {
    if (user.id && cookieUserId) {
      const clientUserId = user.id;

      if (clientUserId !== cookieUserId) {
        window.location.reload();
      }
    }
  }, [user, cookieUserId, window.location]);

  const getParsedUser = () => ({
    data: {
      currentUser: user,
    },
  });

  return token ? (
    <DisconnectHandler
      getUser={getParsedUser}
      location={location}
      userId={user?.id}
      logoutHandler={logOut}
      accessToken={user?.access_token}
      logoutDelay={1200000}
      domainValue={domainValue}
    />
  ) : null;
};

export default DisconnectHandlerWrapper;
