import React from 'react';

const CheckmarkIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7968 14.6876C16.3197 13.1647 17.1753 11.0991 17.1753 8.94539C17.1753 6.79165 16.3197 4.72612 14.7968 3.2032C13.2739 1.68028 11.2084 0.824707 9.05464 0.824707C6.9009 0.824707 4.83537 1.68028 3.31245 3.2032C3.29047 3.21724 3.27181 3.2359 3.25776 3.25789C3.23578 3.27193 3.21712 3.29059 3.20308 3.31257C1.68015 4.8355 0.824585 6.90102 0.824585 9.05476C0.824585 11.2085 1.68015 13.274 3.20308 14.7969C4.726 16.3199 6.79153 17.1754 8.94526 17.1754C11.099 17.1754 13.1645 16.3199 14.6875 14.7969C14.7094 14.7829 14.7281 14.7642 14.7421 14.7423C14.7641 14.7282 14.7828 14.7096 14.7968 14.6876V14.6876ZM15.875 9.00007C15.8749 10.6076 15.3109 12.1641 14.2812 13.3985L4.60151 3.71882C5.60539 2.88317 6.82655 2.35078 8.12202 2.18399C9.4175 2.01719 10.7337 2.22289 11.9165 2.777C13.0993 3.33111 14.0998 4.2107 14.8008 5.3128C15.5019 6.4149 15.8745 7.6939 15.875 9.00007ZM2.12495 9.00007C2.12503 7.39258 2.68903 5.83606 3.7187 4.60164L13.3984 14.2813C12.3945 15.117 11.1734 15.6494 9.87788 15.8162C8.5824 15.983 7.26622 15.7773 6.0834 15.2231C4.90059 14.669 3.90011 13.7894 3.19907 12.6873C2.49803 11.5852 2.12545 10.3062 2.12495 9.00007V9.00007Z"
      fill="#D32F2F"
    />
  </svg>
);

export default CheckmarkIcon;
