import React from 'react';
import { Helmet } from 'react-helmet-async';

export const SEO = ({ title, description, name, type, ogTitle, ogDescription }) => (
  <Helmet>
    {/* Standard metadata tags */}
    <title>{title}</title>
    <meta name="description" content={description} />
    {/* End standard metadata tags */}
    {/* Facebook tags */}
    <meta property="og:type" content={type} />
    <meta property="og:url" content="https://transparenterra.com/" />
    <meta property="og:title" content={ogTitle} />
    <meta property="og:description" content={ogDescription} />
    <meta property="og:image" content="https://transparenterra.com/images/logo-968.png" />
    {/* End Facebook tags */}
    {/* Twitter tags */}
    <meta name="twitter:creator" content={name} />
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={ogTitle} />
    <meta name="twitter:description" content={ogDescription} />
    <meta name="twitter:image" content="https://transparenterra.com/images/logo-968.png" />
    <meta property="twitter:domain" content="transparenterra.com" />
    <meta property="twitter:url" content="https://transparenterra.com/" />
    {/* End Twitter tags */}
  </Helmet>
);
