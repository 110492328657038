import React, { useState } from 'react';
import { Image, MUIDialogActions as DialogActions, Button, Tooltip, VideocamIcon } from 'tt-ui-kit'; // TODO: remove
import CallMenu from '../CallMenu';
import AddMembersMenu from '../AddMembersMenu';

import MicWhiteIconSVG from '../assets/mic-white-icon.svg';
import MicWhiteMuteIconSVG from '../assets/mic-white-mute-icon.svg';
import WarningIconSVG from '../assets/warning-icon.svg';
import VideoOffIconSVG from '../assets/video-off-icon.svg';
import AddUserIconSVG from '../assets/add-user-icon.svg';
import ScreenShareIconSVG from '../assets/share-screen-icon.svg';
import CallEndWhiteIconSVG from '../assets/call-end-white-icon.svg';
import CallWhiteIconSVG from '../assets/call-white-icon.svg';
import WatchIconSVG from '../assets/watch-icon.svg';

import styles from './Footer.module.scss';

const Footer = ({
  deviceExist,
  answerCall,
  rejectCall,
  cancelCall,
  endCall,
  shareScreen,
  muteAudio,
  unmuteAudio,
  offVideo,
  onVideo,
  videoOff,
  status,
  audioMuted,
  timer,
  size,
  changeSize,
}) => {
  const [isAddUserMenuOpen, setIsAddUserMenuOpen] = useState(false);

  const addUser = () => {
    changeSize('xl');
    setIsAddUserMenuOpen(!isAddUserMenuOpen);
  };

  return (
    <DialogActions className={styles.dialogFooter}>
      {size === 'xl' && (
        <div className={styles.dialogLeftActions}>
          {audioMuted ? (
            <Tooltip
              title={deviceExist.includes('audio') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <Button
                  type="icon"
                  disabled={deviceExist.includes('audio')}
                  onClick={() => unmuteAudio()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <Image src={MicWhiteIconSVG} width={13} height={13} />
                  {deviceExist.includes('audio') && (
                    <Image
                      src={WarningIconSVG}
                      width={19}
                      height={19}
                      className={styles.warningIcon}
                    />
                  )}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              type="icon"
              disabled={deviceExist.includes('audio')}
              onClick={() => muteAudio()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <Image src={MicWhiteMuteIconSVG} width={13} height={13} />
            </Button>
          )}
          {videoOff ? (
            <Tooltip
              title={deviceExist.includes('video') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <Button
                  type="icon"
                  disabled={deviceExist.includes('video')}
                  onClick={() => onVideo()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <VideocamIcon sx={{ color: '#ffffff', fontSize: 17 }} />
                  {deviceExist.includes('video') && (
                    <Image
                      src={WarningIconSVG}
                      width={19}
                      height={19}
                      className={styles.warningIcon}
                    />
                  )}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              type="icon"
              disabled={deviceExist.includes('video')}
              onClick={() => offVideo()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <Image src={VideoOffIconSVG} width={13} height={13} />
            </Button>
          )}
        </div>
      )}
      <div className={styles.dialogActions}>
        {size === 'xs' &&
          (audioMuted ? (
            <Tooltip
              title={deviceExist.includes('audio') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <Button
                  type="icon"
                  disabled={deviceExist.includes('audio')}
                  onClick={() => unmuteAudio()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <Image src={MicWhiteIconSVG} width={13} height={13} />
                  {deviceExist.includes('audio') && (
                    <Image
                      src={WarningIconSVG}
                      width={19}
                      height={19}
                      className={styles.warningIcon}
                    />
                  )}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              type="icon"
              disabled={deviceExist.includes('audio')}
              onClick={() => muteAudio()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <Image src={MicWhiteMuteIconSVG} width={13} height={13} />
            </Button>
          ))}
        {size === 'xs' &&
          (videoOff ? (
            <Tooltip
              title={deviceExist.includes('video') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <Button
                  type="icon"
                  disabled={deviceExist.includes('video')}
                  style={deviceExist.includes('video') ? { pointerEvents: 'none' } : {}}
                  onClick={() => onVideo()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <VideocamIcon sx={{ color: '#ffffff', fontSize: 17 }} />
                  {deviceExist.includes('video') && (
                    <Image
                      src={WarningIconSVG}
                      width={19}
                      height={19}
                      className={styles.warningIcon}
                    />
                  )}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              type="icon"
              disabled={deviceExist.includes('video')}
              onClick={() => offVideo()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <Image src={VideoOffIconSVG} width={13} height={13} />
            </Button>
          ))}
        {status === 'answered' && (
          <Button
            type="icon"
            onClick={addUser}
            className={`${styles.actionButton} ${styles.actionButtonBlue}`}
          >
            <Image src={AddUserIconSVG} width={13} height={13} />
          </Button>
        )}
        {status === 'answered' && (
          <Button
            type="icon"
            onClick={() => shareScreen()}
            className={`${styles.actionButton} ${styles.actionButtonBlue}`}
          >
            <Image src={ScreenShareIconSVG} width={13} height={13} />
          </Button>
        )}
        {status === 'answered' && <CallMenu />}
        {status === 'answered' && (
          <Button
            type="icon"
            className={`${styles.actionButton} ${styles.actionButtonRed}`}
            onClick={() => endCall()}
          >
            <Image src={CallEndWhiteIconSVG} width={13} height={13} />
          </Button>
        )}
        {status === 'incoming' && (
          <Button
            type="icon"
            className={`${styles.actionButton} ${styles.actionButtonRed}`}
            onClick={() => rejectCall()}
          >
            <Image src={CallEndWhiteIconSVG} width={13} height={13} />
          </Button>
        )}
        {status === 'outgoing' && (
          <Button
            type="icon"
            className={`${styles.actionButton} ${styles.actionButtonRed}`}
            onClick={() => cancelCall()}
          >
            <Image src={CallEndWhiteIconSVG} width={13} height={13} />
          </Button>
        )}
        {status === 'incoming' && (
          <Button
            type="icon"
            className={`${styles.actionButton} ${styles.actionButtonGreen}`}
            onClick={() => answerCall()}
          >
            <Image src={CallWhiteIconSVG} width={13} height={13} />
          </Button>
        )}
      </div>
      {status === 'answered' && size === 'xl' && (
        <div className={styles.timer}>
          <Image src={WatchIconSVG} width={20} height={20} />
          <span>{timer}</span>
        </div>
      )}
      <AddMembersMenu open={isAddUserMenuOpen} onClose={() => setIsAddUserMenuOpen(false)} />
    </DialogActions>
  );
};
export default Footer;
